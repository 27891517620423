// import { createContext, useState, useCallback } from "react";

// export const CallHistoryContext = createContext({
//   callHistory: [],
//   setCallHistory: () => {},
// });

// export const CallHistoryProvider = ({ children }) => {
//   const [callHistory, setCallHistoryState] = useState([]);

//   const setCallHistory = useCallback((newCall) => {
//     setCallHistoryState((prevCallHistory) => [...prevCallHistory, newCall]);
//   }, []);

//   const value = { callHistory, setCallHistory };

//   return (
//     <CallHistoryContext.Provider value={value}>
//       {children}
//     </CallHistoryContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect, useCallback } from "react";
import { saveState, loadState } from "../utils/storageUtil"; // Adjust the import path as needed

export const CallHistoryContext = createContext({
  callHistory: [],
  setCallHistory: () => {},
});

export const CallHistoryProvider = ({ children }) => {
  // Load the call history from localStorage, or default to an empty array
  const [callHistory, setCallHistoryState] = useState(
    () => loadState("callHistory") || []
  );

  // Update function that adds a new call to the history and saves to localStorage
  // const setCallHistory = useCallback((newCall) => {
  //   setCallHistoryState((prevCallHistory) => {
  //     const updatedCallHistory = [...prevCallHistory, newCall];
  //     saveState("callHistory", updatedCallHistory); // Save every time a new call is added
  //     return updatedCallHistory;
  //   });
  // }, []);

  const setCallHistory = useCallback((newCall) => {
    setCallHistoryState((prevCallHistory) => {
      if (Array.isArray(newCall)) {
        // If newCall is an array, it means we want to replace the history (e.g., clear it)
        saveState("callHistory", newCall);
        return newCall;
      } else {
        // Otherwise, we add the new call to the history
        const updatedCallHistory = [...prevCallHistory, newCall];
        saveState("callHistory", updatedCallHistory); // Save every time a new call is added
        return updatedCallHistory;
      }
    });
  }, []);

  // This useEffect is now primarily for initializing the state from localStorage,
  // as saving is handled in the useCallback to ensure immediate persistence
  useEffect(() => {
    saveState("callHistory", callHistory);
  }, [callHistory]);

  const value = { callHistory, setCallHistory };

  return (
    <CallHistoryContext.Provider value={value}>
      {children}
    </CallHistoryContext.Provider>
  );
};
