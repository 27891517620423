import "./footer.styles.css";

const Footer = () => {
  return (
    <div className="footer">
      <p>Powered by VirtualPBX&reg; 2024</p>
    </div>
  );
};

export default Footer;
