import React, { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil"; // Ensure this import path is correct

export const CallerIDContext = createContext({
  isCallerIDEnabled: false,
  setIsCallerIDEnabled: () => {},
  callerIDNumber: "",
  setCallerIDNumber: () => {},
  callerIDAvailableNumbers: [],
  setCallerIDAvailableNumbers: () => {},
});

export const CallerIDProvider = ({ children }) => {
  // Load state from localStorage or use default values
  const [isCallerIDEnabled, setIsCallerIDEnabled] = useState(
    () => loadState("isCallerIDEnabled") || false
  );

  const [callerIDNumber, setCallerIDNumber] = useState(
    () => loadState("callerIDNumber") || ""
  );

  const [callerIDAvailableNumbers, setCallerIDAvailableNumbers] = useState(
    () => loadState("callerIDAvailableNumbers") || []
  );

  // Save state changes to localStorage
  useEffect(() => {
    saveState("isCallerIDEnabled", isCallerIDEnabled);
  }, [isCallerIDEnabled]);

  useEffect(() => {
    saveState("callerIDNumber", callerIDNumber);
  }, [callerIDNumber]);

  useEffect(() => {
    saveState("callerIDAvailableNumbers", callerIDAvailableNumbers);
  }, [callerIDAvailableNumbers]);

  const value = {
    isCallerIDEnabled,
    setIsCallerIDEnabled,
    callerIDNumber,
    setCallerIDNumber,
    callerIDAvailableNumbers,
    setCallerIDAvailableNumbers,
  };

  return (
    <CallerIDContext.Provider value={value}>
      {children}
    </CallerIDContext.Provider>
  );
};
