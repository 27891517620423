import React, { useContext, useEffect, useState } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { CallerIDContext } from "../../contexts/caller-id.context";
import { UserContext } from "../../contexts/user.context.jsx";
import { KazooAPI as api } from "../../utils/kazoo.js";
import "./caller-id-control.styles.scss";

// const numbers = ["+16506697273", "+16506697274", "+16506697275"];

const CallerIDControl = () => {
  const {
    isCallerIDEnabled,
    setIsCallerIDEnabled,
    callerIDNumber,
    setCallerIDNumber,
    callerIDAvailableNumbers,
  } = useContext(CallerIDContext);
  const { currentUser } = useContext(UserContext);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [availableNumbers, setAvailableNumbers] = useState([]);

  const handleNumberChange = async (event) => {
    if (event.target.value !== "" && event.target.value !== callerIDNumber) {
      // console.log(event.target.value);
      const newToken = await api.refreshToken(
        currentUser.auth_hash,
        currentUser.account.account_name
      );
      const resp = await api.setUserCallerID(
        currentUser.user_id,
        currentUser.account.account_id,
        event.target.value,
        newToken
      );
      console.log(resp.caller_id);
      setIsCallerIDEnabled(true);
      setCallerIDNumber(event.target.value);
    } else if (event.target.value === "") {
      // console.log("Disable caller ID");
      const newToken = await api.refreshToken(
        currentUser.auth_hash,
        currentUser.account.account_name
      );
      const resp = await api.setUserCallerID(
        currentUser.user_id,
        currentUser.account.account_id,
        event.target.value,
        newToken
      );
      setIsCallerIDEnabled(false);
      setCallerIDNumber(event.target.value);
      console.log(resp.caller_id);
    }
  };

  useEffect(() => {
    setAvailableNumbers(callerIDAvailableNumbers);
  }, [callerIDAvailableNumbers]);

  useEffect(() => {
    setSelectedNumber(isCallerIDEnabled ? callerIDNumber : "");
  }, [isCallerIDEnabled, callerIDNumber]);

  return (
    <Box className="caller-id-control">
      <Box className="controls">
        <FormControl className="form-control">
          <InputLabel
            className="caller-id-number-label"
            id="caller-id-number-label"
          >
            Caller-ID Number
          </InputLabel>
          <Select
            labelId="caller-id-number-label"
            id="caller-id-number"
            value={selectedNumber}
            onChange={handleNumberChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {availableNumbers.map((number) => (
              <MenuItem key={number} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default CallerIDControl;
