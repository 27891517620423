import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { UserContext } from "../../contexts/user.context.jsx";
import { KazooAPI as api } from "../../utils/kazoo.js";
import ComponentLoading from "../component-loading/component-loading.component.jsx";
import "./voicemail-panel.styles.scss";

const VoiceMailPanel = () => {
  const { currentUser, setToken } = useContext(UserContext);
  const [voicemails, setVoicemails] = useState([]);
  const [playingId, setPlayingId] = useState(null);
  const [audio, setAudio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [transcriptions, setTranscriptions] = useState({});

  useEffect(() => {
    if (currentUser) {
      const fetchVoicemails = async () => {
        try {
          const newToken = await api.refreshToken(
            currentUser.auth_hash,
            currentUser.account.account_name
          );
          setToken(newToken);
          const response = await api.getUserVoicemails(
            currentUser.account.account_id,
            currentUser.voicemailBoxID,
            newToken
          );
          const transformedResponse = response.map((voicemail) => ({
            ...voicemail,
            date: convertTimestampToReadableString(voicemail.timestamp),
            message: `Duration: ${voicemail.length} seconds`,
          }));
          setVoicemails(transformedResponse);
        } catch (error) {
          console.error("Error fetching voicemails:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchVoicemails();
    }
  }, [currentUser, setToken]);

  const convertTimestampToReadableString = (timestamp) => {
    const secondsInADay = 86400;
    const days = Math.floor(timestamp / secondsInADay);
    const seconds = timestamp % secondsInADay;

    const baseDate = new Date(0);
    baseDate.setUTCFullYear(0);
    baseDate.setUTCMonth(0);
    baseDate.setUTCDate(1);
    baseDate.setUTCHours(0, 0, 0, 0);

    baseDate.setUTCDate(baseDate.getUTCDate() + days);
    baseDate.setUTCSeconds(baseDate.getUTCSeconds() + seconds);

    return baseDate.toLocaleString();
  };

  const handlePlayPause = async (voicemail) => {
    if (playingId === voicemail.media_id) {
      audio.pause();
      setPlayingId(null);
    } else {
      if (audio) {
        audio.pause();
      }
      const newToken = await api.refreshToken(
        currentUser.auth_hash,
        currentUser.account.account_name
      );
      setToken(newToken);
      const blob = await api.getRawVoicemail(
        currentUser.account.account_id,
        currentUser.voicemailBoxID,
        voicemail.media_id,
        newToken
      );

      if (blob.error) {
        console.error("Error fetching voicemail audio:", blob.error);
        return;
      }

      const url = URL.createObjectURL(blob);
      const newAudio = new Audio(url);
      setAudio(newAudio);
      newAudio.play();
      setPlayingId(voicemail.media_id);
    }
  };

  const handleDelete = async (voicemail) => {
    const newToken = await api.refreshToken(
      currentUser.auth_hash,
      currentUser.account.account_name
    );
    setToken(newToken);
    const resp = api.deleteVoicemail(
      currentUser.account.account_id,
      currentUser.voicemailBoxID,
      voicemail.media_id,
      newToken
    );
    console.log(resp);
    setVoicemails((prevVoicemails) =>
      prevVoicemails.filter((vm) => vm.media_id !== voicemail.media_id)
    );
  };

  const handleViewTranscription = async (voicemail) => {
    try {
      const newToken = await api.refreshToken(
        currentUser.auth_hash,
        currentUser.account.account_name
      );
      setToken(newToken);
      const transcription = await api.getVoicemailTranscription(
        currentUser.account.account_id,
        currentUser.voicemailBoxID,
        voicemail.media_id,
        newToken
      );
      setTranscriptions((prevTranscriptions) => ({
        ...prevTranscriptions,
        [voicemail.media_id]: transcription,
      }));
    } catch (error) {
      console.error("Error fetching voicemail transcription:", error);
    }
  };

  return (
    <Box className="main-content">
      <Card className="voicemail-list" sx={{ position: "relative" }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Voicemails
          </Typography>
          {loading ? (
            <ComponentLoading />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Caller</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {voicemails.map((voicemail) => (
                    <TableRow key={voicemail.media_id}>
                      <TableCell>{voicemail.caller_id_name}</TableCell>
                      <TableCell>{voicemail.date}</TableCell>
                      <TableCell>{voicemail.length / 1000}</TableCell>
                      <TableCell>
                        {transcriptions[voicemail.media_id] ? (
                          transcriptions[voicemail.media_id]
                        ) : (
                          <IconButton
                            onClick={() => handleViewTranscription(voicemail)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handlePlayPause(voicemail)}>
                          {playingId === voicemail.media_id ? (
                            <PauseIcon />
                          ) : (
                            <PlayArrowIcon />
                          )}
                        </IconButton>
                        <IconButton onClick={() => handleDelete(voicemail)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default VoiceMailPanel;
