import React, { useContext } from "react";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import { useSIP } from "../../contexts/SIPContext";
import { PhoneStatusContext } from "../../contexts/phone-status.context";
import { CurrentCallContext } from "../../contexts/current-call.context.jsx";
import { DefaultDeviceContext } from "../../contexts/default-device.context.jsx";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import PhoneIcon from "@mui/icons-material/Phone";

import "./call-history-item.styles.scss";

const CallHistoryItem = ({ status, callerName, callerNumber, timestamp }) => {
  const { makeCall } = useSIP();
  const {
    isOnCall,
    // setIsOnCall
  } = useContext(PhoneStatusContext);
  const { setCurrentCall } = useContext(CurrentCallContext);
  const { defaultDevice } = useContext(DefaultDeviceContext);

  const handleClick = () => {
    // console.log(callerName == null ? callerNumber : callerName);
    // console.log(status);
    setCurrentCall({
      display_name: callerName == null ? callerNumber : callerName,
      display_number: callerNumber,
      call_direction: "outbound",
    });
    callerNumber.length < 10
      ? makeCall(`sip:${callerNumber}@${defaultDevice.realm}`)
      : makeCall(callerNumber);
    // setIsOnCall(true);
  };

  const getCallStatusIcon = (status) => {
    switch (status) {
      case "missed":
        return <CallMissedIcon />;
      case "inbound":
        return <CallReceivedIcon />;
      case "incoming":
        return <CallReceivedIcon />;
      case "outgoing":
        return <CallMadeIcon />;
      case "rejected":
        return <CallMissedOutgoingIcon />;
      default:
        return null;
    }
  };

  return (
    <ListItem className="call-history-item">
      <ListItemIcon>{getCallStatusIcon(status)}</ListItemIcon>
      <ListItemText
        primary={callerName}
        secondary={`${callerNumber} - ${new Date(timestamp).toLocaleString()}`}
      />
      <IconButton
        color="primary"
        component="span"
        onClick={handleClick}
        disabled={isOnCall}
      >
        <PhoneIcon />
      </IconButton>
    </ListItem>
  );
};

export default CallHistoryItem;
