// src/components/loading/loading.component.jsx
import React from "react";
import { Box, CircularProgress } from "@mui/material";
import "./component-loading.styles.scss";

const ComponentLoading = () => (
  <Box className="loading-container">
    <CircularProgress />
  </Box>
);

export default ComponentLoading;
