import { createContext, useState, useContext } from "react";

const UIStateContext = createContext();

export const useUIState = () => useContext(UIStateContext);

export const UIStateProvider = ({ children }) => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);
  const [isContactsPanelOpen, setIsContactsPanelOpen] = useState(false);

  // Use the theme's spacing to calculate the collapsed width
  const navBarWidth = 220;
  const navBarCollapsedWidth = 65; // Assuming 8 * 8px + 1px
  const contactsPanelWidth = 320;

  // ... rest of your code

  const value = {
    isNavBarOpen,
    setIsNavBarOpen,
    isContactsPanelOpen,
    setIsContactsPanelOpen,
    navBarWidth,
    navBarCollapsedWidth,
    contactsPanelWidth,
  };

  return (
    <UIStateContext.Provider value={value}>{children}</UIStateContext.Provider>
  );
};
