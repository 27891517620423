export const initializeTabManager = () => {
  const channel = new BroadcastChannel("tab-detection");

  const handleMessage = (event) => {
    console.log("Received message:", event.data);
    if (event.data.action === "check") {
      channel.postMessage({ action: "exists", params: event.data.params });
    } else if (event.data.action === "exists") {
      if (event.data.params) {
        console.log("Received parameters from new tab:", event.data.params);
        console.log("Logging parameters to App.js:", event.data.params);
        window.originalTabParameters = event.data.params; // Store parameters globally
      }
      focusExistingTab();
      alert(
        "Another tab is already open with the same application. This tab will now display a blank page."
      );
      document.body.innerHTML = ""; // Clear the content of the body
      document.head.innerHTML = ""; // Clear the content of the head
    }
  };

  const checkOnUnload = () => {
    channel.postMessage("closing");
  };

  const focusExistingTab = () => {
    window.open("", "_self").focus();
  };

  // Extract URL parameters
  const params = new URLSearchParams(window.location.search);
  const parameters = {};
  params.forEach((value, key) => {
    parameters[key] = value;
  });

  console.log("Current tab parameters:", parameters);

  channel.postMessage({ action: "check", params: parameters });
  channel.addEventListener("message", handleMessage);
  window.addEventListener("beforeunload", checkOnUnload);

  return () => {
    channel.removeEventListener("message", handleMessage);
    window.removeEventListener("beforeunload", checkOnUnload);
    channel.close();
  };
};
