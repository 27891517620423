// import { createContext, useState } from "react";

// export const userDevicesContext = createContext({
//   userDevices: [],
//   setUserDevices: () => {},
// });

// export const UserDevicesProvider = ({ children }) => {
//   const [userDevices, setUserDevices] = useState([]);
//   const value = { userDevices, setUserDevices };
//   return (
//     <userDevicesContext.Provider value={value}>
//       {children}
//     </userDevicesContext.Provider>
//   );
// };

import { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil.js"; // Make sure to adjust the import path as needed

export const userDevicesContext = createContext({
  userDevices: [],
  setUserDevices: () => {},
});

export const UserDevicesProvider = ({ children }) => {
  // Initialize state with values from localStorage if available
  const [userDevices, setUserDevices] = useState(
    () => loadState("userDevices") || []
  );

  // Save state to localStorage whenever it changes
  useEffect(() => {
    saveState("userDevices", userDevices);
  }, [userDevices]);

  const value = { userDevices, setUserDevices };

  return (
    <userDevicesContext.Provider value={value}>
      {children}
    </userDevicesContext.Provider>
  );
};
