import React, { useContext, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import NavBar from "../../components/nav-bar/nav-bar.component";
import RightPanel from "../../components/right-panel/right-panel.component";
import SettingsPanel from "../../components/settings-panel/settings-panel.component";
import IncomingCallNotification from "../../components/incoming-call-notification/incoming-call-notification.component";
import { SettingsContext } from "../../contexts/settings.context";
import { IncomingCallContext } from "../../contexts/incoming-call.context";
import { PhoneStatusContext } from "../../contexts/phone-status.context";
import CallHistoryPanel from "../../components/call-history-panel/call-history-panel.component";
import FeatureCodesPanel from "../../components/feature-codes-panel/feature-codes-panel.compoent";
import VoicemailPanel from "../../components/voicemail-panel/voicemail-panel.component";
import ContactPanel from "../../components/contacts-panel/contacts-panel.component";
import "./main.styles.scss";

const Main = () => {
  const { isSettingsOpen, setIsSettingsOpen } = useContext(SettingsContext);
  const { incomingCall } = useContext(IncomingCallContext);
  const { isRegistered } = useContext(PhoneStatusContext);
  const [contactPanelOpen, setContactPanelOpen] = useState(false);
  const [displayIncomingCallNotification, setDisplayIncomingCallNotification] =
    useState(false);

  const toggleContactPanel = () => {
    setContactPanelOpen(!contactPanelOpen);
  };

  const toggleDrawer = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  useEffect(() => {
    if (!isRegistered) {
      setIsSettingsOpen(true);
    }
  }, [isRegistered, setIsSettingsOpen]);

  useEffect(() => {
    if (incomingCall) {
      setDisplayIncomingCallNotification(true);
    } else {
      setDisplayIncomingCallNotification(false);
    }
  }, [incomingCall]);

  return (
    <Box className="app">
      <Box className="app-container">
        <NavBar toggleContactPanel={toggleContactPanel} />
        {contactPanelOpen && <ContactPanel />}
        {isSettingsOpen && (
          <SettingsPanel isOpen={isSettingsOpen} toggleDrawer={toggleDrawer} />
        )}
        {displayIncomingCallNotification && (
          <IncomingCallNotification
            open={displayIncomingCallNotification}
            callerName={
              incomingCall ? incomingCall._remote_identity._display_name : ""
            }
            callerNumber={
              incomingCall ? incomingCall._remote_identity._uri._user : ""
            }
          ></IncomingCallNotification>
        )}
        <Routes>
          <Route path="/" element={<CallHistoryPanel />} />
          <Route path="/feature-codes" element={<FeatureCodesPanel />} />
          <Route path="/voicemail" element={<VoicemailPanel />} />
        </Routes>
      </Box>
      <RightPanel />
    </Box>
  );
};

export default Main;
