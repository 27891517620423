// import { createContext, useState } from "react";

// export const DirectoryContext = createContext({
//   directory: [],
//   setDirectory: () => {},
// });

// export const DirectoryProvider = ({ children }) => {
//   const [directory, setDirectory] = useState([]);
//   const value = { directory, setDirectory };
//   return (
//     <DirectoryContext.Provider value={value}>
//       {children}
//     </DirectoryContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil"; // Adjust the import path to match your project structure

export const DirectoryContext = createContext({
  directory: [],
  setDirectory: () => {},
});

export const DirectoryProvider = ({ children }) => {
  // Initialize the directory state with values from localStorage or default to an empty array
  const [directory, setDirectory] = useState(
    () => loadState("directory") || []
  );

  // Save the directory state to localStorage whenever it changes
  useEffect(() => {
    saveState("directory", directory);
  }, [directory]);

  const value = { directory, setDirectory };

  return (
    <DirectoryContext.Provider value={value}>
      {children}
    </DirectoryContext.Provider>
  );
};
