import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./contexts/user.context";
import { AgentProvider } from "./contexts/agent.context";
import { DirectoryProvider } from "./contexts/directory.context";
import { CallHistoryProvider } from "./contexts/call-history.context";
import { SettingsProvider } from "./contexts/settings.context";
import { DefaultDeviceProvider } from "./contexts/default-device.context";
import { DefaultMicrophoneProvider } from "./contexts/default-microphone.context";
import { DefaultSpeakerProvider } from "./contexts/default-speaker.context";
import { UserDevicesProvider } from "./contexts/user-devices.context";
import { PhoneStatusProvider } from "./contexts/phone-status.context";
import { CurrentCallProvider } from "./contexts/current-call.context";
import { ConsultCallProvider } from "./contexts/consultCallContext.context";
// import { CallStackProvider } from "./contexts/callStackContext.context";
import { NumberToDialProvider } from "./contexts/number-to-dial.context";
import { IncomingCallProvider } from "./contexts/incoming-call.context";
import { SIPProvider } from "./contexts/SIPContext";
import { AudioContextProvider } from "./contexts/audioContext.context";
import { UIStateProvider } from "./contexts/ui-state.context";
import { CallerIDProvider } from "./contexts/caller-id.context";
import { CallToTransferProvider } from "./contexts/callToTransferContext.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <CallerIDProvider>
          <AgentProvider>
            {/* <CallStackProvider> */}
            <CurrentCallProvider>
              <CallToTransferProvider>
                <ConsultCallProvider>
                  <DirectoryProvider>
                    <DefaultMicrophoneProvider>
                      <DefaultSpeakerProvider>
                        <DefaultDeviceProvider>
                          <PhoneStatusProvider>
                            <NumberToDialProvider>
                              <IncomingCallProvider>
                                <CallHistoryProvider>
                                  <SettingsProvider>
                                    <AudioContextProvider>
                                      <SIPProvider>
                                        <UserDevicesProvider>
                                          {/* <SettingsProvider> */}
                                          <UIStateProvider>
                                            <App />
                                          </UIStateProvider>
                                          {/* </SettingsProvider> */}
                                        </UserDevicesProvider>
                                      </SIPProvider>
                                    </AudioContextProvider>
                                  </SettingsProvider>
                                </CallHistoryProvider>
                              </IncomingCallProvider>
                            </NumberToDialProvider>
                          </PhoneStatusProvider>
                        </DefaultDeviceProvider>
                      </DefaultSpeakerProvider>
                    </DefaultMicrophoneProvider>
                  </DirectoryProvider>
                </ConsultCallProvider>
              </CallToTransferProvider>
            </CurrentCallProvider>
            {/* </CallStackProvider> */}
          </AgentProvider>
        </CallerIDProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
