import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import LoginForm from "../../components/login-form/login-form.component";
import "./login.styles.scss";

const Login = () => {
  return (
    <div>
      <Header />
      <LoginForm></LoginForm>
      <Footer />
    </div>
  );
};

export default Login;
