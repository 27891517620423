import { createContext, useState } from "react";

export const CurrentCallContext = createContext({
  currentCall: null,
  setCurrentCall: () => {},
});

export const CurrentCallProvider = ({ children }) => {
  const [currentCall, setCurrentCall] = useState(null);
  const value = { currentCall, setCurrentCall };

  return (
    <CurrentCallContext.Provider value={value}>
      {children}
    </CurrentCallContext.Provider>
  );
};
