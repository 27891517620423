/**
 * Normalizes a phone number by stripping unwanted characters,
 * adding country code if necessary, and handling extensions.
 *
 * @param {string} number - The phone number to normalize.
 * @returns {string} - The normalized phone number with extension if present.
 */
export const normalizeNumber = (number) => {
  // Detect and separate extension
  let [mainNumber, extension] = number.split(/ext|x/, 2);
  let digits = mainNumber.replace(/\D/g, "");

  // Check if the number starts with '1' and is 11 digits or if it's 10 digits long
  if (
    (digits.startsWith("1") && digits.length === 11) ||
    digits.length === 10
  ) {
    // Assume it's a US number, strip the leading '1' if present
    digits = digits.length === 11 ? digits.substring(1) : digits;
    // Normalize extension, if present
    if (extension) {
      extension = extension.replace(/\D/g, "");
      // Append extension using a format recognized in E.164 standard
      return `+1${digits};ext=${extension}`;
    }
    // Return the number with the US country code
    return `+1${digits}`;
  }

  // If the number does not fit the above criteria or has an extension
  // that doesn't match expected lengths, return it without modification
  return number;
};

/**
 * Formats a given phone number to the E.164 standard, taking into account
 * extensions.
 *
 * @param {string} number - The phone number to format.
 * @returns {string} - The formatted phone number with extension if present.
 */
export const formatE164 = (number) => {
  let [mainNumber, extension] = number.split(/ext|x/, 2);
  let digits = mainNumber.replace(/\D/g, "");

  // Check if the main number is 10 digits long after normalization
  if (digits.length === 10) {
    // Format to E.164, append extension if present
    if (extension) {
      extension = extension.replace(/\D/g, "");
      return `+1${digits};ext=${extension}`;
    }
    return `+1${digits}`;
  }

  // If number is not 10 digits long or handling of extension is needed but incorrect, return it as is
  return number;
};
