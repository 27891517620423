// import { createContext, useState } from "react";

// export const DefaultMicrophoneContext = createContext({
//   defaultMicrophone: null,
//   setDefaultMicrophone: () => {},
// });

// export const DefaultMicrophoneProvider = ({ children }) => {
//   const [defaultMicrophone, setDefaultMicrophone] = useState(null);
//   const value = { defaultMicrophone, setDefaultMicrophone };
//   return (
//     <DefaultMicrophoneContext.Provider value={value}>
//       {children}
//     </DefaultMicrophoneContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil"; // Adjust the import path as necessary

export const DefaultMicrophoneContext = createContext({
  defaultMicrophone: null,
  setDefaultMicrophone: () => {},
});

export const DefaultMicrophoneProvider = ({ children }) => {
  // Initialize the defaultMicrophone state with a value from localStorage, or default to null
  const [defaultMicrophone, setDefaultMicrophone] = useState(
    () => loadState("defaultMicrophone") || null
  );

  // Save changes to localStorage whenever defaultMicrophone changes
  useEffect(() => {
    saveState("defaultMicrophone", defaultMicrophone);
  }, [defaultMicrophone]);

  const value = { defaultMicrophone, setDefaultMicrophone };

  return (
    <DefaultMicrophoneContext.Provider value={value}>
      {children}
    </DefaultMicrophoneContext.Provider>
  );
};
