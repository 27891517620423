import React, {
  createContext,
  useContext,
  useState,
  // useEffect,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { UserContext } from "./user.context";
import { DefaultMicrophoneContext } from "./default-microphone.context";
import { DefaultSpeakerContext } from "./default-speaker.context";
import { PhoneStatusContext } from "./phone-status.context";
import { NumberToDialContext } from "./number-to-dial.context";
import { IncomingCallContext } from "./incoming-call.context";
import { CallHistoryContext } from "./call-history.context";
import { useAudioContext } from "./audioContext.context";
import { CurrentCallContext } from "./current-call.context";
// import { CallStackContext } from "./callStackContext.context";
import { ConsultCallContext } from "./consultCallContext.context";
import { CallToTransferContext } from "./callToTransferContext.context";
import JsSIP from "jssip";

const SIP_SERVER_URL = "wss://webrtc.virtualpbx.net:5065";

export const SIPContext = createContext();

export const useSIP = () => {
  return useContext(SIPContext);
};

// JsSIP.debug.enable("JsSIP:*");
JsSIP.debug.disable("JsSIP:*");

export const SIPProvider = ({ children }) => {
  const { isRegistered, setIsRegistered, setIsOnConsultCall } =
    useContext(PhoneStatusContext);

  const [registrationError, setRegistrationError] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [consultCallId, setConsultCallId] = useState("");
  const consultCallIdRef = useRef("");
  const [ua, setUa] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const audioRef = useRef(null);
  const { defaultMicrophone } = useContext(DefaultMicrophoneContext);
  const { defaultSpeaker } = useContext(DefaultSpeakerContext);
  const { currentUser } = useContext(UserContext);
  const { isOnCall, setIsOnCall } = useContext(PhoneStatusContext);
  const isOnCallRef = useRef(isOnCall);
  const { setNumberToDial } = useContext(NumberToDialContext);
  const { setCurrentCall } = useContext(CurrentCallContext);
  // const { callStack, addCall, removeCall, updateCall } =
  //   useContext(CallStackContext);
  const { setConsultCall, consultCall } = useContext(ConsultCallContext);
  const { setCallToTransfer, callToTransfer } = useContext(
    CallToTransferContext
  );
  const callToTransferRef = useRef("");
  const { incomingCall, setIncomingCall } = useContext(IncomingCallContext);
  const { setCallHistory } = useContext(CallHistoryContext);
  const { audioContext, startRinging, stopRinging, playHangUpTone } =
    useAudioContext();

  const [audioDevice, setAudioDevice] = useState({
    mic: !defaultMicrophone ? null : defaultMicrophone.deviceId,
    speaker: !defaultSpeaker ? null : defaultSpeaker.deviceId,
  });

  useEffect(() => {
    callToTransferRef.current = callToTransfer;
  }, [callToTransfer]);

  useEffect(() => {
    consultCallIdRef.current = consultCallId;
  }, [consultCallId]);

  useEffect(() => {
    isOnCallRef.current = isOnCall;
  }, [isOnCall]);

  const initilizeUA = (device) => {
    console.log("UA Initilizing");

    const socket = new JsSIP.WebSocketInterface(SIP_SERVER_URL);
    const configuration = {
      sockets: [socket],
      uri: `${device.username}@${device.realm}`,
      password: device.password,
    };
    const sipUa = new JsSIP.UA(configuration);

    sipUa.on("newRTCSession", (data) => {
      if (!isOnCallRef.current || data.originator !== "remote") {
        // console.log("PERRROOOOOOO");
        setCurrentSession(data.session);
        if (data.originator === "remote") {
          setIncomingCall(data.session);
          startRinging();
          console.log("INCOMING CALL...", data.session._remote_identity);
        }
        //it was accepted before with progress I hear the ringback
        data.session.on("progress", () => {
          // setCurrentSession(data.session);
          const remoteStream = new MediaStream();

          //NEW BLOCK FOR TESTING INCOMING CALLS
          if (data.session.connection) {
            data.session.connection.getReceivers().forEach((receiver) => {
              const track = receiver.track;
              if (track) {
                remoteStream.addTrack(track);
              }
            });
          }
          // else {
          //   console.error("Connection is not yet established.");
          // }

          if (audioRef.current) {
            audioRef.current.srcObject = remoteStream;
          }
        });

        data.session.on("accepted", () => {
          stopRinging();
          const remoteStream = new MediaStream();
          if (data.session.connection) {
            data.session.connection.getReceivers().forEach((receiver) => {
              const track = receiver.track;
              if (track) {
                remoteStream.addTrack(track);
              }
            });
            if (audioRef.current) {
              audioRef.current.srcObject = remoteStream;
            }
          } else {
            console.error(
              "Connection is not established during 'accepted' event."
            );
          }
        });

        data.session.on("ended", async () => {
          // console.log("DATA SESSION", data.session._id);
          // console.log("CONSULT CALL ID", consultCallIdRef);
          // console.log("TRANSFER CALL", callToTransferRef.current);
          if (data.session._id === consultCallIdRef.current) {
            setConsultCallId(null);
            setIsOnConsultCall(false);
            if (callToTransferRef?.current.isOnHold().local) {
              // callToTransferRef.current.unhold();
              setCurrentSession(callToTransferRef.current);
              // console.log(
              //   "WTF Switched to original call.",
              //   callToTransferRef.current
              // );
              // attachMediaStream(callToTransferRef.current);
            }
            return;
          }

          await setCallHistory({
            status: data.session._direction,
            callerName: data.session._remote_identity._display_name,
            callerNumber: data.session._remote_identity._uri._user,
            timestamp: data.session._start_time,
          });
          setIsOnCall(false);
          setIncomingCall(null);
          setNumberToDial("");
          setCurrentSession(null);
        });

        data.session.on("failed", async (event) => {
          stopRinging();
          if (
            data.originator === "remote" &&
            event.cause === JsSIP.C.causes.CANCELED
          ) {
            console.log(
              "Missed call from: ",
              data.session._remote_identity._display_name
            );

            await setCallHistory({
              status: "missed",
              callerName: data.session._remote_identity._display_name,
              callerNumber: data.session._remote_identity._uri._user,
              timestamp: new Date(),
            });
            setIsOnCall(false);
            setIncomingCall(null);
            setNumberToDial("");
            setCurrentSession(null);
          }
        });
      } else {
        setCallHistory({
          status: "rejected",
          callerName: data.session._remote_identity._display_name,
          callerNumber: data.session._remote_identity._uri._user,
          timestamp: new Date(),
        });
        data.session.terminate();
        console.log("Already on call");
      }
    });

    sipUa.on("newMessage", (event) => {
      if (event.request.method === "NOTIFY") {
        console.log("Received presence NOTIFY:", event.request.body);
      } else {
        console.log(event);
      }
    });

    sipUa.on("registered", () => {
      setIsRegistered(true);
      console.log("UA registered...");
      setRegistrationError(null);
    });

    sipUa.on("unregistered", (event) => {
      setIsRegistered(false);
      if (event.cause) {
        setRegistrationError(event.cause);
      }
    });

    sipUa.on("connected", (event) => {
      console.log("ua connected");
    });

    sipUa.on("disconnected", (event) => {
      console.log("ua disconnected");
    });

    sipUa.on("registrationFailed", (event) => {
      console.log("ua registration failed");
      setIsRegistered(false);
      setRegistrationError(event.cause);
    });

    sipUa.on("sipEvent", (e) => {
      console.log("Incoming SIP message:", e);
    });

    setUa(sipUa);
    sipUa.start();
  };

  const register = () => {
    if (!ua) {
      console.error("UA not initialized");
      return;
    }
    ua.start();
  };

  const unregister = async () => {
    if (ua) {
      await ua.unregister();
    }
  };

  //OLD version fully working with one call
  // const makeCall = useCallback(
  //   (destination) => {
  //     if (!ua) {
  //       console.error("UA not initialized");
  //       return;
  //     }
  //     if (!destination.includes("@")) {
  //       destination = `${destination}@${currentUser.account.realm}`;
  //     }

  //     const options = {
  //       mediaConstraints: {
  //         audio: {
  //           deviceId: audioDevice.mic,
  //         },
  //         video: false,
  //       },
  //     };

  //     ua.call(destination, options);
  //     setIsOnCall(true);
  //   },
  //   [ua, currentUser?.account?.realm, audioDevice.mic, setIsOnCall]
  // );
  const makeCall = useCallback(
    (destination) => {
      if (!ua) {
        console.error("UA not initialized");
        return;
      }
      if (!destination.includes("@")) {
        destination = `${destination}@${currentUser.account.realm}`;
      }

      const options = {
        mediaConstraints: {
          audio: {
            deviceId: audioDevice.mic,
          },
          video: false,
        },
        //   pcConfig: {
        //     iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        //   },
      };

      const session = ua.call(destination, options);
      session.on("accepted", () => attachMediaStream(session));
      session.on("ended", () => {
        if (audioRef.current) {
          audioRef.current.srcObject = null;
        }
      });

      setIsOnCall(true);
    },
    [ua, currentUser?.account?.realm, audioDevice.mic, setIsOnCall]
  );

  const hangup = () => {
    console.log("Hanging up...", currentSession);
    if (currentSession) {
      setIsOnCall(false);
      currentSession.terminate();
      // setCurrentSession(null); ///CHANGED TO MAKE SURE i CAN RETREIVE THE CALL WHEN
      playHangUpTone();
    }
  };

  const answerCall = async () => {
    await setCurrentCall({
      display_name: incomingCall._remote_identity._display_name,
      display_number: incomingCall._remote_identity._uri._user,
      call_direction: "inbound",
    });
    if (incomingCall) {
      incomingCall.answer({
        mediaConstraints: {
          audio: {
            deviceId: audioDevice.mic,
          },
          video: false,
        },
      });
      setIsOnCall(true);
      setIncomingCall(null);
    }
  };

  const declineCall = () => {
    console.log("Rejecting call...", currentSession);
    setCallHistory({
      status: "rejected",
      callerName: currentSession._remote_identity._display_name,
      callerNumber: currentSession._remote_identity._uri._user,
      timestamp: new Date(),
    });
    if (incomingCall) {
      incomingCall.terminate();
      setIncomingCall(null);
      setIsOnCall(false);
      setCurrentSession(null);
    }
  };

  const holdCall = useCallback(() => {
    //THE FULLOWING IF IS A HACK TO MAKE HANG UP OF CONSULT CALL WORK PROPERLY
    if (currentSession.isOnHold().local) {
      currentSession.unhold();
      attachMediaStream(currentSession);
      return;
    }
    if (currentSession) {
      currentSession.hold();
      console.log("Call put on hold.");
      if (audioRef.current) {
        audioRef.current.srcObject = null; // Clear the audio stream when on hold
      }
    } else {
      console.error("No active call to put on hold.");
    }
  }, [currentSession]);

  const unholdCall = useCallback(() => {
    if (currentSession) {
      currentSession.unhold();
      console.log("Call resumed from hold.");
      attachMediaStream(currentSession); // Re-attach media stream when unholding
    } else {
      console.error("No call to resume from hold.");
    }
  }, [currentSession]);

  const blindTransfer = (targetExtension) => {
    if (!currentSession) {
      console.error("No active call session available for transfer.");
      return;
    }
    currentSession.refer(targetExtension);
    setIsOnCall(false);
    console.log(`Blind transfer to ${targetExtension} initiated.`);
  };

  const warmTransfer = useCallback(() => {
    if (!consultCall || !callToTransfer) {
      console.error("Both consultCall and callToTransfer must be set.");
      return;
    }
    try {
      // const targetUri = `sip:${callToTransfer._remote_identity._uri._user}@${callToTransfer._remote_identity._uri._host}`;
      // // Perform the warm transfer
      // consultCall.refer(targetUri);

      const targetUri = `sip:${consultCall._remote_identity._uri._user}@${callToTransfer._remote_identity._uri._host}`;
      // Perform the warm transfer
      callToTransfer.refer(targetUri);
      consultCall.terminate();

      // Clean up after transfer
      setIsOnCall(false);
      setConsultCall(null);
      setCallToTransfer(null);
      setCurrentSession(null);
      console.log("Warm transfer initiated.");
    } catch (error) {
      console.error("Failed to initiate warm transfer:", error);
    }
  }, [
    consultCall,
    callToTransfer,
    setConsultCall,
    setCallToTransfer,
    setIsOnCall,
    setCurrentSession,
  ]);

  // INSTANT CALL BACK FUNCTIONALITY
  // const warmTransfer = useCallback(() => {
  //   if (!consultCall || !callToTransfer) {
  //     console.error("Both consultCall and callToTransfer must be set.");
  //     return;
  //   }
  //   try {
  //     // Determine the target URI based on consultCall and callToTransfer
  //     const targetUri = `sip:${callToTransfer._remote_identity._uri._user}@${callToTransfer._remote_identity._uri._host}`;

  //     // Perform the warm transfer using REFER
  //     consultCall.refer(targetUri);

  //     // Additional custom SIP message to instruct the server to merge calls
  //     // Assuming we have a function to send a custom SIP message
  //     sendCustomSipMessage({
  //       method: "REFER",
  //       to: targetUri,
  //       body: `Refer-To: <${targetUri}>; replaces=${consultCall.id}`,
  //     });

  //     // Terminate the consult call after the REFER is sent
  //     // consultCall.terminate();

  //     // Clean up after transfer
  //     setIsOnCall(false);
  //     setConsultCall(null);
  //     setCallToTransfer(null);
  //     setCurrentSession(null);
  //     console.log(
  //       "Warm transfer initiated and calls are being merged server-side."
  //     );
  //   } catch (error) {
  //     console.error("Failed to initiate warm transfer:", error);
  //   }
  // }, [
  //   consultCall,
  //   callToTransfer,
  //   setConsultCall,
  //   setCallToTransfer,
  //   setIsOnCall,
  //   setCurrentSession,
  // ]);
  // // Function to send a custom SIP message
  // const sendCustomSipMessage = (message) => {
  //   // Implement the logic to send a custom SIP message to Kamailio/FreeSWITCH
  //   // This might involve using a WebSocket connection or other signaling mechanism
  //   // to communicate with the SIP server
  // };

  const toggleBetweenCalls = useCallback(() => {
    if (!consultCall || !callToTransfer) {
      console.log("Both consultCall and callToTransfer must be set.");
      return;
    }

    if (consultCall.isOnHold().local) {
      consultCall.unhold();
      callToTransfer.hold();
      // console.log("Switched to consult call.", consultCall);
      attachMediaStream(consultCall);
    } else {
      consultCall.hold();
      callToTransfer.unhold();
      // console.log("Switched to call to transfer.", callToTransfer);
      attachMediaStream(callToTransfer);
    }
  }, [consultCall, callToTransfer]);

  const attachMediaStream = (session) => {
    const remoteStream = new MediaStream();
    // console.log(session.connection);
    if (session.connection) {
      session.connection.getReceivers().forEach((receiver) => {
        const track = receiver.track;
        if (track) {
          remoteStream.addTrack(track);
        }
      });
      if (audioRef.current) {
        audioRef.current.srcObject = remoteStream;
      }
    } else {
      console.error("Connection is not established.");
    }
  };

  const initiateConsultCall = useCallback(
    (destination) => {
      if (!ua) {
        console.error("UA not initialized");
        return;
      }
      if (!destination.includes("@")) {
        destination = `${destination}@${currentUser.account.realm}`;
      }

      const options = {
        mediaConstraints: {
          audio: {
            deviceId: audioDevice.mic,
          },
          video: false,
        },
        // pcConfig: {
        //   iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        // },
      };
      // console.log("CURRENT SESSION IN INITIATE...", currentSession);
      setCallToTransfer(currentSession);
      holdCall();
      const session = ua.call(destination, options);
      session.on("accepted", () => attachMediaStream(session));
      session.on("ended", () => {
        if (audioRef.current) {
          audioRef.current.srcObject = null;
        }
      });
      setConsultCall(session);
      // console.log("TESTING...", session._id);
      // console.log("TRANSFER CALL IN INITIATE...", callToTransfer);
      setConsultCallId(session._id);
      consultCallIdRef.current = session._id;
    },
    [
      ua,
      currentUser?.account?.realm,
      audioDevice.mic,
      setCallToTransfer,
      setConsultCall,
      setConsultCallId,
      holdCall,
      currentSession,
    ]
  );

  const hangupConsultCall = useCallback(() => {
    // console.log("Hanging up ConsultCall", consultCall);
    if (consultCall) {
      consultCall.terminate();
      setConsultCall(null);
      if (callToTransfer.isOnHold().local) {
        callToTransfer.unhold();
        setCurrentSession(callToTransfer);
        // console.log("Switched to original call.", callToTransfer);
        attachMediaStream(callToTransfer);
      }
    }
  }, [consultCall, setConsultCall, callToTransfer]);

  const changeAudioDevice = (micId, speakerId) => {
    setAudioDevice({ mic: micId, speaker: speakerId });
  };

  const changeMicrophone = (micId) => {
    setAudioDevice((prevDevice) => ({
      ...prevDevice,
      mic: micId,
    }));
  };

  const changeSpeaker = useCallback(
    (speakerId) => {
      // Ensure audioContext is not null
      if (!audioContext) {
        console.warn("AudioContext is not available.");
        return;
      }

      // Your existing logic to change the speaker
      const oscillator = audioContext.createOscillator();
      const dst = audioContext.createMediaStreamDestination();
      oscillator.connect(dst);
      oscillator.start();
      oscillator.stop(audioContext.currentTime + 0.1); // Stop after a brief moment

      if (!audioRef.current) {
        audioRef.current = new Audio();
      }

      // Set the silent audio track as the source
      audioRef.current.srcObject = dst.stream;

      // Attempt to change the speaker
      if (typeof audioRef.current.setSinkId !== "undefined") {
        audioRef.current
          .setSinkId(speakerId)
          .then(() => {
            console.log(`Speaker changed to: ${speakerId}`);
            // Disconnect the silent audio track after setting speaker
            oscillator.disconnect();
          })
          .catch((err) => {
            console.error(`Could not change speaker: ${err}`);
            // Disconnect the silent audio track if there was an error
            oscillator.disconnect();
          });
      } else {
        console.warn("Browser does not support output device selection.");
      }

      // Update the state to reflect the change
      setAudioDevice((prevDevice) => ({
        ...prevDevice,
        speaker: speakerId,
      }));
    },
    [audioContext, audioRef]
  );

  const muteMicrophone = () => {
    if (currentSession && currentSession.connection) {
      currentSession.connection.getSenders().forEach((sender) => {
        if (sender.track && sender.track.kind === "audio") {
          sender.track.enabled = false;
        }
      });
      setIsMuted(true);
    }
  };

  const unmuteMicrophone = () => {
    if (currentSession && currentSession.connection) {
      currentSession.connection.getSenders().forEach((sender) => {
        if (sender.track && sender.track.kind === "audio") {
          sender.track.enabled = true;
        }
      });
      setIsMuted(false);
    }
  };

  const toggleMute = () => {
    if (isMuted) {
      unmuteMicrophone();
    } else {
      muteMicrophone();
    }
  };

  const sendDTMF = (tone) => {
    if (currentSession && currentSession.isEstablished()) {
      currentSession.sendDTMF(tone, {
        duration: 100, // Duration of the tone in milliseconds
        interToneGap: 50, // Interval between tones in milliseconds
      });
      // console.log(`DTMF tone ${tone} sent`);
    } else {
      console.error("No active call to send DTMF");
    }
  };

  const destroyUA = async () => {
    try {
      if (ua) {
        await ua.terminateSessions();
        await ua.unregister({ all: true });
        await ua.stop();
      }
      // console.log(ua);
    } catch (error) {
      console.log(error);
    }
  };

  const value = {
    initilizeUA,
    destroyUA,
    incomingCall,
    isRegistered,
    registrationError,
    register,
    unregister,
    makeCall,
    changeAudioDevice,
    hangup,
    answerCall,
    declineCall,
    isMuted,
    muteMicrophone,
    unmuteMicrophone,
    toggleMute,
    changeMicrophone,
    changeSpeaker,
    sendDTMF,
    holdCall,
    unholdCall,
    blindTransfer,
    warmTransfer,
    toggleBetweenCalls,
    initiateConsultCall,
    hangupConsultCall,
  };

  return (
    <SIPContext.Provider value={value}>
      {children}
      <audio ref={audioRef} autoPlay hidden />
    </SIPContext.Provider>
  );
};

export default SIPProvider;
