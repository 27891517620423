import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  // Popover, TextField
} from "@mui/material";
import CallTransferButton from "../call-transfer-button/call-transfer-button.component.jsx";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import PhoneIcon from "@mui/icons-material/Phone";
// import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CallEnd } from "@mui/icons-material";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import { PhoneStatusContext } from "../../contexts/phone-status.context";
import { CurrentCallContext } from "../../contexts/current-call.context";
import { DefaultDeviceContext } from "../../contexts/default-device.context.jsx";
import { NumberToDialContext } from "../../contexts/number-to-dial.context";
import {
  normalizeNumber,
  // formatE164
} from "../../utils/numberUtils.js";
import { useSIP } from "../../contexts/SIPContext";

import "./call-control.styles.scss";

function CallControl() {
  const [micStatus, setMicStatus] = useState(true);
  const {
    isOnCall,
    // setIsOnCall
  } = useContext(PhoneStatusContext);
  const { setCurrentCall } = useContext(CurrentCallContext);
  const { numberToDial } = useContext(NumberToDialContext);
  const { defaultDevice } = useContext(DefaultDeviceContext);
  const {
    hangup,
    muteMicrophone,
    unmuteMicrophone,
    makeCall,
    holdCall,
    unholdCall,
    // blindTransfer,
  } = useSIP();
  const [isCallOnHold, setIsCallOnHold] = useState(false);
  // const [transferTo, setTransferTo] = useState("");

  const handleMicStatus = () => {
    setMicStatus(!micStatus);
    if (micStatus) {
      muteMicrophone();
    } else {
      unmuteMicrophone();
    }
  };

  const handleEndCall = () => {
    hangup();
    setCurrentCall(null);
    setIsCallOnHold(false);
  };

  const setPhoneOnCall = () => {
    if (numberToDial === "") return;
    const normalizedNumber = normalizeNumber(numberToDial);

    setCurrentCall({
      display_name: normalizedNumber,
      display_number: normalizedNumber,
      call_direction: "outbound",
    });

    makeCall(
      normalizedNumber.includes("+")
        ? normalizedNumber
        : `sip:${normalizedNumber}@${defaultDevice.realm}`
    );

    // setIsOnCall(true);
  };

  const toggleCallOnHold = () => {
    if (!isCallOnHold) {
      holdCall();
      setIsCallOnHold(true);
    } else {
      unholdCall();
      setIsCallOnHold(false);
    }
  };

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handlePhoneForwardClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? "phone-forward-popover" : undefined;

  // const handleInputChange = (event) => {
  //   setTransferTo(event.target.value);
  // };

  // const handleCallTransfer = () => {
  //   handleClose();
  //   // setIsOnCall(false);
  //   setCurrentCall(null);
  //   setIsCallOnHold(false);
  //   setTransferTo("");
  //   blindTransfer(formatE164(normalizeNumber(transferTo)));
  // };

  return (
    <Box className="call-control-container">
      <IconButton
        color="primary"
        onClick={handleMicStatus}
        className="call-control-button"
      >
        {micStatus ? (
          <MicIcon className="call-control-icon" />
        ) : (
          <MicOffIcon className="call-control-icon" sx={{ color: "gray" }} />
        )}
      </IconButton>
      <IconButton
        color="secondary"
        onClick={!isOnCall ? setPhoneOnCall : handleEndCall}
        className="call-control-button"
        sx={!isOnCall ? { background: "#31b547" } : { background: "red" }}
        // disabled={numberToDial === ""}
      >
        {!isOnCall ? (
          <PhoneIcon className="call-control-icon" sx={{ color: "white" }} />
        ) : (
          <CallEnd className="call-control-icon" sx={{ color: "white" }} />
        )}
      </IconButton>
      <IconButton
        color="primary"
        className="call-control-button"
        disabled={!isOnCall}
        onClick={toggleCallOnHold}
      >
        <PhonePausedIcon className="call-control-icon" />
      </IconButton>
      <CallTransferButton />
      {/* <IconButton
        color="primary"
        className="call-control-button"
        disabled={!isOnCall}
        onClick={handlePhoneForwardClick}
        // sx={buttonSx}
        // onClick={toggleCallOnHold}
      >
        <PhoneForwardedIcon className="call-control-icon" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} display="flex" alignItems="center" gap={1}>
          <TextField
            label="Transfer to"
            variant="outlined"
            size="small"
            fullWidth
            value={transferTo} // Bind the TextField value to the state
            onChange={handleInputChange} // Update the state on every change
          />
          <IconButton
            color="primary"
            onClick={handleCallTransfer}
            sx={{
              // Adjust the size of the IconButton to make it a small square
              width: 48, // or 'theme.spacing(6)' for theme consistency
              height: 48, // or 'theme.spacing(6)' for theme consistency
              // Optionally adjust the padding to ensure the icon is centered if needed
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Popover> */}
    </Box>
  );
}

export default CallControl;
