import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AgentIcon from "@mui/icons-material/SupportAgent";
import CancelIcon from "@mui/icons-material/Cancel";
import UILoading from "../ui-loading/ui-loading.component.jsx";
import { AgentContext } from "../../contexts/agent.context";
import { UserContext } from "../../contexts/user.context";
import { KazooAPI as api } from "../../utils/kazoo.js";

import "./queue-control.styles.scss";

function AgentStatusControl() {
  const { isLoggedIn, setIsLoggedIn, sessionStatus, setSessionStatus } =
    useContext(AgentContext);
  const {
    currentUser,
    // setCurrentUser,
    setToken,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleStartSession = async () => {
    setIsLoading(true);
    try {
      const newToken = await api.refreshToken(
        currentUser.auth_hash,
        currentUser.account.account_name
      );
      // setCurrentUser((currentUser) => ({
      //   ...currentUser,
      //   auth_token: newToken,
      // }));
      setToken(newToken);

      const logIntoQueue = await api.queueBeginSession(
        currentUser.account.account_id,
        currentUser.user_id,
        newToken
      );
      console.log(logIntoQueue);
      setIsLoggedIn(true);
      setSessionStatus(sessionStatus);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (event, newStatus) => {
    setIsLoading(true);
    try {
      if (newStatus !== null && newStatus !== "exit") {
        const newToken = await api.refreshToken(
          currentUser.auth_hash,
          currentUser.account.account_name
        );
        // setCurrentUser((currentUser) => ({
        //   ...currentUser,
        //   auth_token: newToken,
        // }));
        setToken(newToken);
        const statusChanged = await api.queueChangeStatus(
          currentUser.account.account_id,
          currentUser.user_id,
          newStatus.toLowerCase(),
          newToken
        );
        console.log(statusChanged);
        setSessionStatus(newStatus);
      }
      if (newStatus === "exit") {
        const newToken = await api.refreshToken(
          currentUser.auth_hash,
          currentUser.account.account_name
        );
        // setCurrentUser((currentUser) => ({
        //   ...currentUser,
        //   auth_token: newToken,
        // }));

        setToken(newToken);
        const exitQueue = await api.queueExitSession(
          currentUser.account.account_id,
          currentUser.user_id,
          newToken
        );
        console.log(isLoading);
        console.log("Exit queue:", exitQueue);
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ textAlign: "left", position: "relative", margin: 3 }}>
      {isLoading && <UILoading />}
      <Box sx={{ display: "inline-flex", alignItems: "buttom", gap: 1 }}>
        <AgentIcon />
        <Typography variant="subtitle1">Queue Status</Typography>
      </Box>

      <Box
        sx={{
          borderRadius: 1,
          padding: 2,
          marginTop: 0,
        }}
      >
        {!isLoggedIn ? (
          <ToggleButtonGroup
            value={isLoggedIn}
            exclusive
            // onChange={handleStatusChange}
            size="small"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 0,
              marginBottom: 1,
            }}
          >
            <ToggleButton
              className="toggle-button"
              value="start-session"
              color="success"
              onClick={handleStartSession}
              sx={{
                backgroundColor: "green",
                color: "white",
              }}
            >
              <PowerSettingsNewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        ) : (
          <>
            <ToggleButtonGroup
              value={sessionStatus}
              exclusive
              size="small"
              onChange={handleStatusChange}
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 0,
                marginBottom: 1,
              }}
            >
              <ToggleButton
                className="toggle-button"
                value="Ready"
                color="success"
              >
                <CheckCircleIcon />
              </ToggleButton>
              <ToggleButton
                className="toggle-button"
                value="Away"
                color="warning"
              >
                <RemoveCircleIcon />
              </ToggleButton>
              <ToggleButton
                className="toggle-button"
                value="exit"
                color="error"
              >
                <CancelIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        )}
      </Box>
    </Box>
  );
}

export default AgentStatusControl;
