import React, { useContext } from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { DefaultDeviceContext } from "../../contexts/default-device.context.jsx";
import { PhoneStatusContext } from "../../contexts/phone-status.context";
import { CurrentCallContext } from "../../contexts/current-call.context.jsx";
import { useSIP } from "../../contexts/SIPContext";
import "./contact-card.styles.scss";

const ContactCard = ({ name, ext, first_name, last_name, status, avatar }) => {
  // const statusColors = {
  //   available: "green",
  //   away: "orange",
  //   // ... other statuses
  // };
  const { makeCall } = useSIP();
  const { defaultDevice } = useContext(DefaultDeviceContext);
  const {
    isOnCall,
    // setIsOnCall
  } = useContext(PhoneStatusContext);
  const { setCurrentCall } = useContext(CurrentCallContext);

  const handleClick = () => {
    setCurrentCall({
      display_name: name,
      display_number: ext,
      call_direction: "outbound",
    });
    makeCall(`sip:${ext}@${defaultDevice.realm}`);
    // setIsOnCall(true);
  };

  const userInitials = `${first_name.charAt(0)}${last_name.charAt(0)}`;

  return (
    <ListItem className="contact">
      <ListItemAvatar>
        <Avatar
        // src={avatar}
        >
          {" "}
          {userInitials}{" "}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${name}`}
        // secondary={
        //   <>
        //     <ListItemText
        //       primary={`ext: ${ext}`}
        //       sx={{ transform: "scale(0.8)" }}
        //     />
        //   </>
        // }
        // secondary={
        //   <>
        //     <FiberManualRecordIcon
        //       style={{ fontSize: 14, color: statusColors[status] }}
        //     />
        //     {` ${status}`}
        //   </>
        // }
      />
      <IconButton
        color="primary"
        component="span"
        onClick={handleClick}
        disabled={isOnCall}
      >
        <PhoneIcon />
      </IconButton>
    </ListItem>
  );
};

export default ContactCard;
