import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ErrorDialog from "../error-dialog/error-dialog.component";
import { UserContext } from "../../contexts/user.context";
import { AgentContext } from "../../contexts/agent.context.jsx";
import { CallerIDContext } from "../../contexts/caller-id.context.jsx";
import { userDevicesContext } from "../../contexts/user-devices.context";
import { DirectoryContext } from "../../contexts/directory.context";
import UILoading from "../ui-loading/ui-loading.component.jsx";
// import { useAudioContext } from "../../contexts/audioContext.context.jsx";
import { KazooAPI as api } from "../../utils/kazoo.js";
import { useNavigate } from "react-router-dom";

import "./login-form.styles.scss";

const defaultFormFields = {
  username: "",
  password: "",
  accountName: "",
};

const LoginForm = (onLogin) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { username, password, accountName } = formFields;
  const [errorDialog, setErrorDialog] = useState({ open: false, message: "" });
  const { setCurrentUser, setDNDEnabled, setToken } = useContext(UserContext);
  const { setIsAgent, setIsLoggedIn, setSessionStatus } =
    useContext(AgentContext);
  const {
    setIsCallerIDEnabled,
    setCallerIDNumber,
    setCallerIDAvailableNumbers,
  } = useContext(CallerIDContext);
  const { setUserDevices } = useContext(userDevicesContext);
  const { setDirectory } = useContext(DirectoryContext);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const { audioContext } = useAudioContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionDenied) {
      openErrorDialog(
        "You must grant microphone and camera permission to this app"
      );
      navigate("/");
      // Reset the state if necessary
      setPermissionDenied(false);
    }
  }, [permissionDenied, navigate]);

  // async function handleContinue() {
  //   try {
  //     const mediaPermission = await navigator.mediaDevices.getUserMedia({
  //       video: true,
  //       audio: true,
  //     });

  //     const notificationPermission = await Notification.requestPermission();
  //     console.log(mediaPermission);
  //     if (notificationPermission === "granted" && mediaPermission) {
  //       console.log("Permissions granted for notifications and media");
  //       return true; // Permissions granted
  //     } else {
  //       console.error("Permission denied");
  //       return false; // Permissions denied
  //     }
  //   } catch (err) {
  //     console.error("Permission denied by user", err);
  //     return false; // Permissions denied
  //   }
  // }

  async function handleContinue() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      // console.log(devices);
      const hasVideoInput = devices.some(
        (device) => device.kind === "videoinput"
      );
      const hasAudioInput = devices.some(
        (device) => device.kind === "audioinput"
      );

      // console.log(hasAudioInput, hasVideoInput);
      if (!hasVideoInput) {
        openErrorDialog("No camera available...");
        // throw new Error("No camera available...");
      }

      const mediaPermission = await navigator.mediaDevices.getUserMedia({
        audio: hasAudioInput,
      });
      mediaPermission.video = hasVideoInput ? hasVideoInput : "";

      const notificationPermission = await Notification.requestPermission();
      // console.log(mediaPermission);
      if (notificationPermission === "granted" && mediaPermission) {
        console.log("Permissions granted for notifications and media");
        return true; // Permissions granted
      } else {
        console.error("Permission denied");
        return false; // Permissions denied
      }
    } catch (err) {
      console.error("Permission denied by user", err);
      return false; // Permissions denied
    }
  }

  const openErrorDialog = (message) => {
    setErrorDialog({ open: true, message });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const permissionsGranted = await handleContinue();
      if (!permissionsGranted) {
        // Permission was not granted
        openErrorDialog(
          "You must grant microphone and camera permission to this app"
        );
        navigate("/");
        return; // Stop further execution
      }
      const updatedAccountName =
        accountName.toLocaleLowerCase() === "respiri"
          ? `h9sucdd - ${accountName}`
          : accountName;
      setFormFields((prevFields) => ({
        ...prevFields,
        accountName: updatedAccountName,
      }));

      const currentUser = await api.authenticateUser(
        username,
        password,
        updatedAccountName
      );
      // console.log(currentUser);
      if (currentUser.error) return openErrorDialog("Invalid Credentials");
      const token = currentUser.auth_token;
      const accountDetails = await api.getAccountDetails(
        currentUser.account.account_id,
        // currentUser.auth_token
        token
      );
      currentUser.account.realm = accountDetails.realm;
      const userDetails = await api.getUserDetails(
        currentUser.user_id,
        currentUser.account.account_id,
        // currentUser.auth_token
        token
      );
      // console.log(userDetails.caller_id.external?.number);

      const isCallerIDEnabled = !!userDetails.caller_id.external?.number;

      const callerIDNumber = isCallerIDEnabled
        ? userDetails.caller_id.external.number
        : "";

      setIsCallerIDEnabled(isCallerIDEnabled);
      setCallerIDNumber(callerIDNumber);
      const userMainCallflow = await api.getUserMainCallflow(
        currentUser.account.account_id,
        currentUser.user_id,
        // currentUser.auth_token
        token
      );
      // console.log("callFlows", userMainCallflow);
      const callerIDAvailableNumbers = userMainCallflow[0].numbers.filter(
        (number) => number.startsWith("+1")
      );
      const accountExternalNumbers = await api.getAccountExternalNumbers(
        currentUser.account.account_id,
        // currentUser.auth_token
        token
      );
      accountExternalNumbers.forEach((number) => {
        callerIDAvailableNumbers.push(number.number);
      });
      // console.log("accountExternalNumbers", accountExternalNumbers);
      // console.log(callerIDAvailableNumbers);
      // console.log("isCallerIDEnabled", isCallerIDEnabled);
      setCallerIDAvailableNumbers(callerIDAvailableNumbers);
      currentUser.presence_id = userDetails.presence_id;
      const voicemailBox = await api.getUserVoiceMailBox(
        currentUser.account.account_id,
        currentUser.user_id,
        // currentUser.auth_token
        token
      );
      // console.log(voicemailBox);
      voicemailBox
        ? (currentUser.voicemailBoxID = voicemailBox.id)
        : console.log("No voicemailbox found...");
      setCurrentUser(currentUser);
      setToken(token);
      const userDevices = await api.getUserSoftphones(
        currentUser.user_id,
        currentUser.account.account_id,
        // currentUser.auth_token
        token
      );
      setUserDevices(userDevices);

      setDNDEnabled(
        await api.isDNDEnabled(
          currentUser.user_id,
          currentUser.account.account_id,
          // currentUser.auth_token
          token
        )
      );

      const isAgent = await api.queueCheckIsAgent(
        currentUser.account.account_id,
        currentUser.user_id,
        // currentUser.auth_token
        token
      );
      setIsAgent(isAgent);

      // console.log(`user is an Agent: ${isAgent}`);
      const sessionStatus = await api.queueCheckSessionStatus(
        currentUser.account.account_id,
        currentUser.user_id,
        // currentUser.auth_token
        token
      );
      if (sessionStatus !== "not_logged_in") {
        setIsLoggedIn(true);
        setSessionStatus(sessionStatus);
      } else {
        setIsLoggedIn(false);
      }
      // console.log(sessionStatus);

      const directory = await api.getAccountUsers(
        currentUser.account.account_id,
        // currentUser.auth_token
        token
      );
      setDirectory(directory);
      // console.log(directory);
      //PENDING WORK ON CALL HISTORY IN CASE DECIDE TO SYNC IT FROM SOME EVENTS
      // console.log(userDevices);
      navigate("/main");
    } catch (error) {
      openErrorDialog("Invalid Credentials");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // if (isLoading) return <Loading />;

  return (
    <Box
      className="login"
      // component="form"
      // sx={{ "& .MuiTextField-root": { m: 1, width: "55ch" } }}
      noValidate
      autoComplete="off"
    >
      {isLoading && <UILoading />}
      <form className="login-box" onSubmit={handleSubmit}>
        <TextField
          className="textInput"
          autoFocus
          name="username"
          label="Username"
          variant="standard"
          value={username}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          className="textInput"
          name="password"
          label="Password"
          variant="standard"
          type="password"
          value={password}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          className="textInput"
          name="accountName"
          label="Account Name"
          variant="standard"
          value={accountName}
          onChange={handleChange}
          fullWidth
          required
        />
        <Button type="submit" className="submitButton">
          Log In
        </Button>
        <a href="/">Forgot password?</a>
      </form>
      <ErrorDialog
        open={errorDialog.open}
        errorMessage={errorDialog.message}
        onClose={() => setErrorDialog({ ...errorDialog, open: false })} // assuming your ErrorDialog accepts an `onClose` prop
      />
    </Box>
  );
};

export default LoginForm;
