import React, { useContext, useState } from "react";
import {
  Box,
  TextField,
  Popover,
  IconButton,
  List,
  //   ListItem,
  //   ListItemText,
  Button,
} from "@mui/material";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PhoneIcon from "@mui/icons-material/Phone";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { CallEnd } from "@mui/icons-material";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import { normalizeNumber, formatE164 } from "../../utils/numberUtils.js";
import { CurrentCallContext } from "../../contexts/current-call.context";
import { PhoneStatusContext } from "../../contexts/phone-status.context";
import { useSIP } from "../../contexts/SIPContext";

import "./call-transfer-button.styles.scss";

const CallTransferButton = ({ children }) => {
  const {
    // currentCall,
    setCurrentCall,
  } = useContext(CurrentCallContext);
  const { isOnCall, isOnConsultCall, setIsOnConsultCall } =
    useContext(PhoneStatusContext);
  // const [isOnConsultCall, setIsOnConsultCall] = useState(false);
  //   const [setIsCallOnHold] = useState(false);
  const [transferTo, setTransferTo] = useState("");
  const {
    // makeCall,
    blindTransfer,
    warmTransfer,
    toggleBetweenCalls,
    initiateConsultCall,
    hangupConsultCall,
  } = useSIP();
  const [anchorEl, setAnchorEl] = useState(null);
  const [transferType, setTransferType] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "phone-forward-popover" : undefined;

  const handlePhoneForwardClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTransferType(null); // Reset transfer type on each click
  };

  const handleCallTransfer = () => {
    handleClose();
    setCurrentCall(null);
    // setIsCallOnHold(false);
    blindTransfer(formatE164(normalizeNumber(transferTo)));
    setTransferTo("");
  };

  const handleToggleBetweenCalls = () => {
    toggleBetweenCalls();
  };
  const handleWarmTransfer = () => {
    handleClose();
    warmTransfer(formatE164(normalizeNumber(transferTo)));
    setTransferTo("");
  };

  const handleInputChange = (event) => {
    setTransferTo(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeConsultCall = () => {
    initiateConsultCall(transferTo);
    setIsOnConsultCall(true);
    // Implement warm transfer logic here
  };

  const handleHangupConsultCall = () => {
    handleClose();
    hangupConsultCall();
    setIsOnConsultCall(false);
  };

  return (
    <Box sx={{ alignContent: "flex-start" }}>
      <IconButton
        color="primary"
        className="call-control-button"
        disabled={!isOnCall}
        onClick={handlePhoneForwardClick}
      >
        <PhoneForwardedIcon className="call-control-icon" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} display="flex" alignItems="center" gap={1}>
          {transferType === null && (
            <List>
              {/* <Button
                className="button-label"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  // textTransform: "none",
                  color: "black",
                  width: "auto",
                  margin: "10px",
                }}
                key="10"
                onClick={() => handleToggleBetweenCalls()}
                fullWidth
              >
                Toggle call
              </Button> */}
              <Button
                className="button-label"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  // textTransform: "none",
                  color: "black",
                  width: "auto",
                  margin: "10px",
                }}
                key="1"
                onClick={() => setTransferType("blind")}
                fullWidth
              >
                Blind Transfer
              </Button>
              <Button
                className="button-label"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  // textTransform: "none",
                  color: "black",
                  width: "auto",
                  margin: "10px",
                }}
                key="2"
                onClick={() => setTransferType("warm")}
                fullWidth
              >
                Warm Transfer
              </Button>
            </List>
          )}
          {transferType === "blind" && (
            <>
              <TextField
                label="Transfer to"
                variant="outlined"
                size="small"
                fullWidth
                value={transferTo}
                onChange={handleInputChange}
              />
              <IconButton
                color="primary"
                onClick={handleCallTransfer}
                sx={{
                  width: 48,
                  height: 48,
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </>
          )}
          {transferType === "warm" && (
            <>
              <TextField
                label="Consult to"
                variant="outlined"
                size="small"
                fullWidth
                value={transferTo}
                onChange={handleInputChange}
              />
              <IconButton
                color="secondary"
                onClick={
                  !isOnConsultCall ? makeConsultCall : handleHangupConsultCall
                }
                className="call-control-button"
                sx={
                  !isOnConsultCall
                    ? { background: "#31b547" }
                    : { background: "red" }
                }
                // disabled={numberToDial === ""}
              >
                {!isOnConsultCall ? (
                  <PhoneIcon
                    className="call-control-icon"
                    sx={{ color: "white" }}
                  />
                ) : (
                  <CallEnd
                    className="call-control-icon"
                    sx={{ color: "white" }}
                  />
                )}
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleToggleBetweenCalls}
                className="call-control-button"
                disabled={!isOnConsultCall}
              >
                <CompareArrowsIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleWarmTransfer}
                className="call-control-button"
                disabled={!isOnConsultCall}
              >
                <CallMergeIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default CallTransferButton;
