import { useContext, useState } from "react";
import { Box, List, Container } from "@mui/material";
import CallHistoryItem from "../call-history-item/call-history-item.component";
import SearchBar from "../search-bar/search-bar.component";
import { CallHistoryContext } from "../../contexts/call-history.context";
import "./call-history-panel.styles.scss";

const CallHistoryPanel = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { callHistory } = useContext(CallHistoryContext);
  const reversedCallHistory = [...callHistory].reverse();
  return (
    <Box className="main-content">
      <Box className="search-container">
        <Box sx={{ width: "100%" }}>
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            disabled={callHistory.length === 0}
          />
        </Box>
      </Box>
      <Container className="call-history-container">
        <List className="call-history-list">
          {reversedCallHistory
            .filter((call) =>
              call.callerNumber.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((call, index) => (
              <CallHistoryItem
                className="callHistoryItem"
                key={index}
                {...call}
              />
            ))}
        </List>
      </Container>
    </Box>
  );
};

export default CallHistoryPanel;
