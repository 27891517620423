import { useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import HelpIcon from "@mui/icons-material/Help";
import DirectoryIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CallHistoryIcon from "@mui/icons-material/History";
import VoicemailIcon from "@mui/icons-material/Voicemail";
// import ContactsPanel from "../contacts-panel/contacts-panel.component";
import { userDevicesContext } from "../../contexts/user-devices.context";
import { DefaultDeviceContext } from "../../contexts/default-device.context";
import { CallHistoryContext } from "../../contexts/call-history.context.jsx";
import { SettingsContext } from "../../contexts/settings.context";
import { UserContext } from "../../contexts/user.context";
import { DefaultMicrophoneContext } from "../../contexts/default-microphone.context";
import { DefaultSpeakerContext } from "../../contexts/default-speaker.context";
import { useUIState } from "../../contexts/ui-state.context.jsx";
import { useSIP } from "../../contexts/SIPContext.jsx";
import { useNavigate } from "react-router-dom";

// import { useTheme } from "@mui/material/styles";

// import "./nav-bar.styles.scss";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function NavBar({ toggleContactPanel }) {
  // const [open, setOpen] = useState(true);
  const {
    isNavBarOpen,
    setIsNavBarOpen,
    // isContactsPanelOpen,
    // setIsContactsPanelOpen,
  } = useUIState();
  // const [contactsPanelOpen, setContactsPanelOpen] = useState(false);
  const navigate = useNavigate();
  const { setUserDevices } = useContext(userDevicesContext);
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { defaultDevice, setDefaultDevice } = useContext(DefaultDeviceContext);
  const { isSettingsOpen, setIsSettingsOpen } = useContext(SettingsContext);
  const { destroyUA } = useSIP();
  const { setDefaultMicrophone } = useContext(DefaultMicrophoneContext);
  const { setDefaultSpeaker } = useContext(DefaultSpeakerContext);
  const { setCallHistory } = useContext(CallHistoryContext);

  // const theme = useTheme();

  const handleDrawerOpen = () => {
    setIsNavBarOpen(!isNavBarOpen);

    // open ? setOpen(false) : setOpen(true);
  };

  // const toggleContactsPanel = (state) => {
  //   setIsContactsPanelOpen(state);
  // };

  const toggleSettingsDrawer = () => {
    //HANDLE ALERT TO CHANGE THE SETTINGS AND I CAN LOGIN THE SOFTPHONE HERE

    !defaultDevice
      ? setIsSettingsOpen(true)
      : setIsSettingsOpen(!isSettingsOpen);
  };

  const handleLogout = async (event) => {
    console.log("Logging you out...");
    destroyUA();
    setUserDevices(null);
    setDefaultMicrophone(null);
    setDefaultSpeaker(null);
    setDefaultDevice(null);
    setCallHistory([]);
    setCurrentUser(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    // <Box sx={{ display: "flex", zIndex: 20 }}>
    <Drawer className="navbar" variant="permanent" open={isNavBarOpen}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#36454F",
        }}
      >
        <Box>
          <List>
            <ListItem key="1" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isNavBarOpen ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={handleDrawerOpen}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isNavBarOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <MenuIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary=""
                  sx={{ opacity: isNavBarOpen ? 1 : 0, color: "white" }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
          <List>
            <ListItem key="2" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isNavBarOpen ? "initial" : "center",
                  px: 2.5,
                }}
                // onClick={() => toggleContactsPanel(!isContactsPanelOpen)}
                onClick={toggleContactPanel}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isNavBarOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DirectoryIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Contacts"
                  sx={{ opacity: isNavBarOpen ? 1 : 0, color: "white" }}
                />
              </ListItemButton>
            </ListItem>
            {currentUser?.hasOwnProperty("voicemailBoxID") && (
              <ListItem
                key="3"
                disablePadding
                sx={{ display: "block" }}
                onClick={() => handleNavigation("/main/voicemail")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isNavBarOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isNavBarOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <VoicemailIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Voicemail"
                    sx={{ opacity: isNavBarOpen ? 1 : 0, color: "white" }}
                  />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem
              key="4"
              disablePadding
              sx={{ display: "block" }}
              onClick={() => handleNavigation("/main")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isNavBarOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isNavBarOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <CallHistoryIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Call History"
                  sx={{ opacity: isNavBarOpen ? 1 : 0, color: "white" }}
                />
              </ListItemButton>
            </ListItem>
          </List>

          {/* <Divider /> */}

          {/* //TO BE INCLUDED IN FUTURE RLEEASES
            <List>
              <ListItem key="5" disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <SmsIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Conversations"
                    sx={{ opacity: open ? 1 : 0, color: "white" }}
                  />
                </ListItemButton>
              </ListItem>
            </List> */}
        </Box>
        <Box sx={{ mb: "auto" }} />{" "}
        {/* This will push the list at the bottom */}
        <List>
          <ListItem
            key="6"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => handleNavigation("/main/feature-codes")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isNavBarOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isNavBarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FlashOnIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primary="Feature Codes"
                sx={{ opacity: isNavBarOpen ? 1 : 0, color: "white" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="7" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isNavBarOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={toggleSettingsDrawer}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isNavBarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingsIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primary="Settings"
                sx={{ opacity: isNavBarOpen ? 1 : 0, color: "white" }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem key="8" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isNavBarOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() =>
                window.open("https://support.virtualpbx.com", "_blank")
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isNavBarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HelpIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primary="Help"
                sx={{ opacity: isNavBarOpen ? 1 : 0, color: "white" }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem key="9" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isNavBarOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleLogout}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isNavBarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primary="Log Out"
                sx={{ opacity: isNavBarOpen ? 1 : 0, color: "white" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
    // <ContactsPanel
    //   // contacts={contacts}
    //   isOpen={isContactsPanelOpen}
    //   onClose={() => setIsContactsPanelOpen(false)}
    //   onOpen={() => setIsContactsPanelOpen(true)}
    //   sidebarWidth={
    //     isNavBarOpen ? `${drawerWidth}px` : `calc(${theme.spacing(7)} + 1px)`
    //   }
    // />
    //  </Box>
  );
}
