import { Routes, Route, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import Login from "./routes/login/login.component";
import Main from "./routes/main/main.component";
import { UserContext } from "./contexts/user.context";
import { PhoneStatusContext } from "./contexts/phone-status.context.jsx";
import { CurrentCallContext } from "./contexts/current-call.context";
import { NumberToDialContext } from "./contexts/number-to-dial.context";
import { useSIP } from "./contexts/SIPContext";

import { normalizeNumber, formatE164 } from "./utils/numberUtils.js";
import { initializeTabManager } from "./utils/tabManager";

import "./App.scss";

const App = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  // const {
  //   isOnCall,
  //   // setIsOnCall
  // } = useContext(PhoneStatusContext);
  const { setCurrentCall } = useContext(CurrentCallContext);
  const {
    // numberToDial,
    setNumberToDial,
  } = useContext(NumberToDialContext);
  const { makeCall } = useSIP();
  const { isRegistered } = useContext(PhoneStatusContext);

  useEffect(() => {
    const cleanup = initializeTabManager();

    const channel = new BroadcastChannel("tab-detection");

    const handleMessage = (event) => {
      if (event.data.action === "exists" && event.data.params?.phone_number) {
        if (isRegistered) {
          setNumberToDial(
            formatE164(normalizeNumber(event.data.params?.phone_number?.trim()))
          );
          setCurrentCall({
            display_name: formatE164(
              normalizeNumber(event.data.params?.phone_number?.trim())
            ),
            display_number: formatE164(
              normalizeNumber(event.data.params?.phone_number?.trim())
            ),
            call_direction: "outbound",
          });
          makeCall(
            formatE164(normalizeNumber(event.data.params?.phone_number?.trim()))
          );
        }
      }
    };

    channel.addEventListener("message", handleMessage);

    return () => {
      cleanup();
      channel.removeEventListener("message", handleMessage);
      channel.close();
    };
  }, [makeCall, isRegistered, setCurrentCall, setNumberToDial]);

  useEffect(() => {
    if (!currentUser) navigate("/");
  }, [currentUser, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/main/*" element={<Main />} />
    </Routes>
  );
};

export default App;
