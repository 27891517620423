// import React, {
//   createContext,
//   useContext,
//   useState,
//   useEffect,
//   useCallback,
//   useRef,
// } from "react";
// import { SettingsContext } from "./settings.context";

// const AudioContextContext = createContext(undefined);

// const DTMF_FREQUENCIES = {
//   1: [697, 1209],
//   2: [697, 1336],
//   3: [697, 1477],
//   4: [770, 1209],
//   5: [770, 1336],
//   6: [770, 1477],
//   7: [852, 1209],
//   8: [852, 1336],
//   9: [852, 1477],
//   "*": [941, 1209],
//   0: [941, 1336],
//   "#": [941, 1477],
//   A: [697, 1633],
//   B: [770, 1633],
//   C: [852, 1633],
//   D: [941, 1633],
// };

// export const AudioContextProvider = ({ children }) => {
//   const [audioContext, setAudioContext] = useState(null);
//   const { volume } = useContext(SettingsContext);
//   const carrierOscillatorRef = useRef(null);
//   const modulatorOscillatorRef = useRef(null);
//   const isRingingRef = useRef(false);

//   useEffect(() => {
//     const AudioCtx = window.AudioContext || window.webkitAudioContext;
//     if (AudioCtx && !audioContext) {
//       setAudioContext(new AudioCtx());
//     }
//   }, [audioContext]);

//   const playDTMFTone = (digit) => {
//     if (!audioContext) return;

//     const [freq1, freq2] = DTMF_FREQUENCIES[digit];
//     const duration = 200; // Duration in milliseconds

//     const oscillator1 = audioContext.createOscillator();
//     const oscillator2 = audioContext.createOscillator();
//     const gainNode = audioContext.createGain();

//     oscillator1.frequency.value = freq1;
//     oscillator2.frequency.value = freq2;

//     oscillator1.connect(gainNode);
//     oscillator2.connect(gainNode);
//     gainNode.connect(audioContext.destination);

//     gainNode.gain.value = 0.4; // Adjust the volume
//     oscillator1.start(audioContext.currentTime);
//     oscillator2.start(audioContext.currentTime);
//     oscillator1.stop(audioContext.currentTime + duration / 1000);
//     oscillator2.stop(audioContext.currentTime + duration / 1000);
//   };

//   const startRinging = useCallback(() => {
//     isRingingRef.current = true; // Indicate that ringing should start or is in progress

//     const playRingingSound = () => {
//       if (!audioContext || !isRingingRef.current) {
//         // Early exit if the audio context is not available or if ringing has been stopped
//         return;
//       }

//       // Setup for the carrier oscillator
//       const carrierOscillator = audioContext.createOscillator();
//       carrierOscillator.frequency.setValueAtTime(440, audioContext.currentTime);
//       carrierOscillatorRef.current = carrierOscillator; // Save reference for potential stopping

//       // Setup for the modulator oscillator
//       const modulatorOscillator = audioContext.createOscillator();
//       modulatorOscillator.frequency.setValueAtTime(
//         10,
//         audioContext.currentTime
//       );
//       modulatorOscillatorRef.current = modulatorOscillator; // Save reference for potential stopping

//       // Setup for modulation gain to achieve the desired modulation effect
//       const modulationGain = audioContext.createGain();
//       modulationGain.gain.setValueAtTime(0.75, audioContext.currentTime);

//       // Connect the modulator oscillator to the modulation gain's gain attribute
//       modulatorOscillator.connect(modulationGain.gain);

//       // Connect the carrier oscillator to the modulation gain to apply the modulation effect
//       carrierOscillator.connect(modulationGain);

//       // Output the final sound by connecting the modulation gain to the audio context's destination
//       modulationGain.connect(audioContext.destination);

//       // Start the oscillators to produce sound
//       carrierOscillator.start();
//       modulatorOscillator.start();

//       // Schedule stopping the oscillators to simulate the end of a ringing cycle
//       setTimeout(() => {
//         carrierOscillator.stop();
//         carrierOscillator.disconnect();
//         modulatorOscillator.stop();
//         modulatorOscillator.disconnect();

//         if (isRingingRef.current) {
//           // Schedule the next execution with a delay to include a period of silence
//           setTimeout(playRingingSound, 2000); // Adjust this to control the length of the silence period
//         } else {
//           // Cleanup if ringing has been stopped to avoid memory leaks
//           carrierOscillatorRef.current = null;
//           modulatorOscillatorRef.current = null;
//         }
//       }, 1500); // Duration of the ringing sound before stopping
//     };

//     playRingingSound(); // Start the initial ringing cycle
//   }, [audioContext]);

//   const stopRinging = useCallback(() => {
//     isRingingRef.current = false; // Set ringing to false to stop the cycle

//     // Check and stop the carrier oscillator
//     if (carrierOscillatorRef.current) {
//       carrierOscillatorRef.current.stop();
//       carrierOscillatorRef.current.disconnect();
//       carrierOscillatorRef.current = null;
//     }

//     // Check and stop the modulator oscillator
//     if (modulatorOscillatorRef.current) {
//       modulatorOscillatorRef.current.stop();
//       modulatorOscillatorRef.current.disconnect();
//       modulatorOscillatorRef.current = null;
//     }
//   }, []);

//   // Pass both playDTMFTone and startRinging as part of the context value
//   return (
//     <AudioContextContext.Provider
//       value={{ audioContext, playDTMFTone, startRinging, stopRinging }}
//     >
//       {children}
//     </AudioContextContext.Provider>
//   );
// };

// // Update useAudioContext to return the entire context object
// export const useAudioContext = () => {
//   const context = useContext(AudioContextContext);
//   if (context === undefined) {
//     throw new Error(
//       "useAudioContext must be used within an AudioContextProvider"
//     );
//   }
//   return context;
// };

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { SettingsContext } from "./settings.context"; // Adjust the import path as per your project structure

const AudioContextContext = createContext(undefined);

const DTMF_FREQUENCIES = {
  1: [697, 1209],
  2: [697, 1336],
  3: [697, 1477],
  4: [770, 1209],
  5: [770, 1336],
  6: [770, 1477],
  7: [852, 1209],
  8: [852, 1336],
  9: [852, 1477],
  "*": [941, 1209],
  0: [941, 1336],
  "#": [941, 1477],
  A: [697, 1633],
  B: [770, 1633],
  C: [852, 1633],
  D: [941, 1633],
};

export const AudioContextProvider = ({ children }) => {
  const [audioContext, setAudioContext] = useState(null);
  const { volume } = useContext(SettingsContext); // Use volume from SettingsContext
  const carrierOscillatorRef = useRef(null);
  const modulatorOscillatorRef = useRef(null);
  const isRingingRef = useRef(false);

  useEffect(() => {
    const AudioCtx = window.AudioContext || window.webkitAudioContext;
    if (AudioCtx && !audioContext) {
      setAudioContext(new AudioCtx());
    }
  }, [audioContext]);

  const playDTMFTone = useCallback(
    (digit) => {
      if (!audioContext) return;

      const [freq1, freq2] = DTMF_FREQUENCIES[digit];
      const duration = 200; // Duration in milliseconds

      const oscillator1 = audioContext.createOscillator();
      const oscillator2 = audioContext.createOscillator();
      const gainNode = audioContext.createGain();

      oscillator1.frequency.value = freq1;
      oscillator2.frequency.value = freq2;

      oscillator1.connect(gainNode);
      oscillator2.connect(gainNode);
      gainNode.connect(audioContext.destination);

      gainNode.gain.value = volume / 400; // Dynamically set the volume
      oscillator1.start(audioContext.currentTime);
      oscillator2.start(audioContext.currentTime);
      oscillator1.stop(audioContext.currentTime + duration / 1000);
      oscillator2.stop(audioContext.currentTime + duration / 1000);
    },
    [audioContext, volume]
  );

  const startRinging = useCallback(() => {
    isRingingRef.current = true;

    const playRingingSound = () => {
      if (!audioContext || !isRingingRef.current) return;

      const carrierOscillator = audioContext.createOscillator();
      carrierOscillator.frequency.setValueAtTime(440, audioContext.currentTime);
      carrierOscillatorRef.current = carrierOscillator;

      const modulatorOscillator = audioContext.createOscillator();
      modulatorOscillator.frequency.setValueAtTime(
        10,
        audioContext.currentTime
      );
      modulatorOscillatorRef.current = modulatorOscillator;

      const modulationGain = audioContext.createGain();
      modulationGain.gain.setValueAtTime(
        volume / 100,
        audioContext.currentTime
      ); // Use dynamic volume

      modulatorOscillator.connect(modulationGain.gain);
      carrierOscillator.connect(modulationGain);
      modulationGain.connect(audioContext.destination);

      carrierOscillator.start();
      modulatorOscillator.start();

      setTimeout(() => {
        carrierOscillator.stop();
        carrierOscillator.disconnect();
        modulatorOscillator.stop();
        modulatorOscillator.disconnect();

        if (isRingingRef.current) {
          setTimeout(playRingingSound, 2000); // Re-trigger for continuous ringing
        } else {
          carrierOscillatorRef.current = null;
          modulatorOscillatorRef.current = null;
        }
      }, 1500);
    };

    playRingingSound();
  }, [audioContext, volume]);

  const stopRinging = useCallback(() => {
    isRingingRef.current = false;

    if (carrierOscillatorRef.current) {
      carrierOscillatorRef.current.stop();
      carrierOscillatorRef.current.disconnect();
      carrierOscillatorRef.current = null;
    }

    if (modulatorOscillatorRef.current) {
      modulatorOscillatorRef.current.stop();
      modulatorOscillatorRef.current.disconnect();
      modulatorOscillatorRef.current = null;
    }
  }, []);

  const playHangUpTone = useCallback(() => {
    if (!audioContext) return;

    const frequency = 400; // Frequency of the hang-up tone in Hz
    const beepDuration = 200; // Duration of each beep in milliseconds
    const silenceDuration = 100; // Duration of silence between beeps in milliseconds
    const initialDelay = 300; // Initial delay before the first beep in milliseconds
    const volume = 0.5; // Volume of the beep

    const playBeep = (startTime) => {
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();

      oscillator.frequency.value = frequency;
      gainNode.gain.value = volume;

      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      oscillator.start(startTime);
      oscillator.stop(startTime + beepDuration / 1000);
    };

    // Calculate the start time for the first beep, including the initial delay
    const firstBeepTime = audioContext.currentTime + initialDelay / 1000;
    playBeep(firstBeepTime);

    // Schedule the second beep after the first beep and the silence period
    const secondBeepTime =
      firstBeepTime + (beepDuration + silenceDuration) / 1000;
    playBeep(secondBeepTime);
    const thirdBeepTime =
      secondBeepTime + (beepDuration + silenceDuration) / 1000;
    playBeep(thirdBeepTime);
  }, [audioContext]);

  // Pass both playDTMFTone and startRinging as part of the context value

  return (
    <AudioContextContext.Provider
      value={{
        audioContext,
        playDTMFTone,
        startRinging,
        stopRinging,
        playHangUpTone,
      }}
    >
      {children}
    </AudioContextContext.Provider>
  );
};

export const useAudioContext = () => {
  const context = useContext(AudioContextContext);
  if (context === undefined) {
    throw new Error(
      "useAudioContext must be used within an AudioContextProvider"
    );
  }
  return context;
};
