import React from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./feature-codes-panel.styles.scss";

const featureCodesData = [
  {
    title: "Call Center",
    codes: [
      { action: "Agent Login", code: "*20" },
      { action: "Set Agent Ready", code: "*21" },
      { action: "Set Agent Away", code: "*22" },
      { action: "Agent Logout", code: "*23" },
    ],
  },
  {
    title: "Call-Forward",
    codes: [
      { action: "Update Call-Forward", code: "*56" },
      { action: "Enable Call-Forward", code: "*72" },
      { action: "Disable Call-Forward", code: "*73" },
      { action: "Toggle Call-Forward", code: "*74" },
    ],
  },
  {
    title: "Hot-Desking",
    codes: [
      { action: "Enable Hot Desking", code: "*11" },
      { action: "Disable Hot Desking", code: "*12" },
      { action: "Toggle Hot Desking", code: "*13" },
    ],
  },
  {
    title: "Miscellaneous",
    codes: [
      { action: "Direct to Voicemail", code: "**" },
      { action: "Intercom", code: "*0" },
      { action: "Move", code: "6683" },
      { action: "Privacy", code: "*67" },
      { action: "Check Voicemail", code: "*97" },
      { action: "Check Voicemail (auto login)", code: "*98" },
    ],
  },
  {
    title: "Parking",
    codes: [
      { action: "Park and Retrieve", code: "*3" },
      { action: "Valet", code: "*4" },
      { action: "Retrieve", code: "*5" },
    ],
  },
];

const FeatureCodesPanel = () => {
  const leftColumnData = featureCodesData.slice(
    0,
    Math.ceil(featureCodesData.length / 2)
  );
  const rightColumnData = featureCodesData.slice(
    Math.ceil(featureCodesData.length / 2)
  );

  return (
    <Box className="feature-codes-panel">
      <Typography variant="h5" component="div" gutterBottom>
        Feature Codes
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {leftColumnData.map((section, sectionIndex) => (
            <Paper className="feature-codes-section" key={sectionIndex}>
              <Typography variant="h6" className="section-title">
                {section.title}
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Action</TableCell>
                      <TableCell>Code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {section.codes.map((code, codeIndex) => (
                      <TableRow key={codeIndex}>
                        <TableCell>{code.action}</TableCell>
                        <TableCell>{code.code}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {rightColumnData.map((section, sectionIndex) => (
            <Paper className="feature-codes-section" key={sectionIndex}>
              <Typography variant="h6" className="section-title">
                {section.title}
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Action</TableCell>
                      <TableCell>Code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {section.codes.map((code, codeIndex) => (
                      <TableRow key={codeIndex}>
                        <TableCell>{code.action}</TableCell>
                        <TableCell>{code.code}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeatureCodesPanel;
