// import { createContext, useState } from "react";

// export const AgentContext = createContext({
//   isAgent: false,
//   setIsAgent: () => null,
//   isLoggedIn: false,
//   setIsLoggedIn: () => null,
//   sessionStatus: "Away",
//   setSessionStatus: () => null,
// });

// export const AgentProvider = ({ children }) => {
//   const [isAgent, setIsAgent] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [sessionStatus, setSessionStatus] = useState("Away");
//   const value = {
//     isAgent,
//     setIsAgent,
//     isLoggedIn,
//     setIsLoggedIn,
//     sessionStatus,
//     setSessionStatus,
//   };
//   return (
//     <AgentContext.Provider value={value}>{children}</AgentContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil"; // Ensure this import path is correct

export const AgentContext = createContext({
  isAgent: false,
  setIsAgent: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  sessionStatus: "Away",
  setSessionStatus: () => {},
});

export const AgentProvider = ({ children }) => {
  // Load state from localStorage or use default values
  const [isAgent, setIsAgent] = useState(() => loadState("isAgent") || false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => loadState("isLoggedIn") || false
  );
  const [sessionStatus, setSessionStatus] = useState(
    () => loadState("sessionStatus") || "Away"
  );

  // Save state changes to localStorage
  useEffect(() => {
    saveState("isAgent", isAgent);
  }, [isAgent]);

  useEffect(() => {
    saveState("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    saveState("sessionStatus", sessionStatus);
  }, [sessionStatus]);

  const value = {
    isAgent,
    setIsAgent,
    isLoggedIn,
    setIsLoggedIn,
    sessionStatus,
    setSessionStatus,
  };

  return (
    <AgentContext.Provider value={value}>{children}</AgentContext.Provider>
  );
};
