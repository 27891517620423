import React, { createContext, useContext, useState } from "react";

export const CallToTransferContext = createContext();

export const useCallToTransfer = () => {
  return useContext(CallToTransferContext);
};

export const CallToTransferProvider = ({ children }) => {
  const [callToTransfer, setCallToTransfer] = useState(null);

  return (
    <CallToTransferContext.Provider
      value={{ callToTransfer, setCallToTransfer }}
    >
      {children}
    </CallToTransferContext.Provider>
  );
};
