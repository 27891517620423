/**
 * Saves the given state under the specified key in localStorage.
 * Uses JSON.stringify to serialize the state.
 * @param {string} key - The key under which to store the state.
 * @param {*} state - The state to be saved.
 */
export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Log or handle the write error as needed
    console.error("Could not save state to localStorage:", err);
  }
};

/**
 * Loads the state stored under the specified key in localStorage.
 * Uses JSON.parse to deserialize the stored string back into state.
 * @param {string} key - The key from which to load the state.
 * @returns {*} The loaded state, or undefined if no state was found or an error occurred.
 */
export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      // No state found under the key
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    // Log or handle the read error as needed
    console.error("Could not load state from localStorage:", err);
    return undefined;
  }
};

export const clearAllStorage = () => {
  localStorage.clear();
};
