import { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil";

export const SettingsContext = createContext({
  isSettingsOpen: false,
  setIsSettingsOpen: () => {},
  volume: 100,
  setVolume: () => {},
  keysMuted: false,
  setKeysMuted: () => {},
  // sipDevice: "",
  // microphone: "",
  // speaker: "",
});

export const SettingsProvider = ({ children }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [volume, setVolume] = useState(() => loadState("volume") || 100);
  const [keysMuted, setKeysMuted] = useState(
    () => loadState("keysMuted") || false
  );

  // Save volume and keysMuted to localStorage whenever they change
  useEffect(() => {
    saveState("volume", volume);
  }, [volume]);

  useEffect(() => {
    saveState("keysMuted", keysMuted);
  }, [keysMuted]);
  const value = {
    isSettingsOpen,
    setIsSettingsOpen,
    volume,
    setVolume,
    keysMuted,
    setKeysMuted,
  };
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};
