import { createContext, useState } from "react";

export const PhoneStatusContext = createContext({
  isOnCall: false,
  isOnConsultCall: false,
  setIsOnConsultCall: () => {},
  setIsOnCall: () => {},
  isRegistered: false,
  setIsRegistered: () => {},
  isConnecting: false,
  setIsConnecting: () => {},
});

export const PhoneStatusProvider = ({ children }) => {
  const [isOnCall, setIsOnCall] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isOnConsultCall, setIsOnConsultCall] = useState(false);
  const value = {
    isOnCall,
    setIsOnCall,
    isOnConsultCall,
    setIsOnConsultCall,
    isRegistered,
    setIsRegistered,
    isConnecting,
    setIsConnecting,
  };
  return (
    <PhoneStatusContext.Provider value={value}>
      {children}
    </PhoneStatusContext.Provider>
  );
};
