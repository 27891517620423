// kazooAPI.js
import MD5 from "crypto-js/md5";

const PORT = "8443";
const API_BASE_URL = `https://public-api.virtualpbx.com:${PORT}/v2`; // Assuming that the API is running on HTTPS on port 8443

class KazooAPI {
  // Utility function to make a fetch call
  async makeRequest(
    endpoint,
    method = "GET",
    body = null,
    authToken,
    responseType = "json"
  ) {
    try {
      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
          ...(authToken ? { "X-Auth-Token": authToken } : {}),
        },
      };

      if (
        body &&
        (method === "POST" || method === "PUT" || method === "PATCH")
      ) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(`${API_BASE_URL}${endpoint}`, options);

      if (!response.ok) {
        throw new Error(`Kazoo API Error: ${response.statusText}`);
      }

      return responseType === "blob"
        ? await response.blob()
        : await response.json();
    } catch (error) {
      console.error("Error making request:", error.message);
      return { error: error.message };
    }
  }

  authenticateUser = async (username, password, account) => {
    try {
      var auth_hash = MD5(username + ":" + password).toString();
      const payload = {
        data: {
          credentials: auth_hash,
          account_name: account,
        },
      };
      // return await this.makeRequest("/user_auth", "PUT", payload);
      const response = await this.makeRequest("/user_auth", "PUT", payload);
      if (response.error) return response;
      // console.log(response.data);
      const currentUser = {
        user_id: response.data.owner_id,
        auth_token: response.auth_token,
        first_name: response.data.user_info.first_name,
        last_name: response.data.user_info.last_name,
        isAdmin: response.data.user_info.priv_level === "admin" ? true : false,
        username: username,
        auth_hash: auth_hash,
        account: {
          account_name: account,
          account_id: response.data.account_id,
        },
      };
      return currentUser;
    } catch (error) {
      return error;
    }
  };

  validateToken = async (authToken) => {
    try {
      const response = await this.makeRequest(
        "/token_auth",
        "GET",
        null,
        authToken
      );
      if (response.error) {
        return false;
        // throw new Error(response.error);
      }
      // return response.auth_token;
      return true;
    } catch (error) {
      console.error(`ERROR validating token: ${error.message}`);
      throw error;
    }
  };

  refreshToken = async (userHash, accountName) => {
    try {
      const payload = {
        data: {
          credentials: userHash,
          account_name: accountName,
        },
      };
      // return await this.makeRequest("/user_auth", "PUT", payload);
      const response = await this.makeRequest("/user_auth", "PUT", payload);
      // console.log(response);
      if (response.error) return response;
      return response.auth_token;
    } catch (error) {
      return error;
    }
  };

  getUserDetails = async (userID, accountID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/users/${userID}`,
        "GET",
        null,
        authToken
      );
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  };

  isDNDEnabled = async (userID, accountID, authToken) => {
    try {
      const response = await this.getUserDetails(userID, accountID, authToken);
      // console.log(response.do_not_disturb.enabled);
      return response.do_not_disturb.enabled;
    } catch (error) {
      return error;
    }
  };

  setDNDStatus = async (userID, accountID, DND_status, authToken) => {
    try {
      const user = await this.getUserDetails(userID, accountID, authToken);
      user.do_not_disturb.enabled = DND_status;
      const payload = { data: user };

      const response = await this.makeRequest(
        `/accounts/${accountID}/users/${userID}`,
        "POST",
        payload,
        authToken
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getUserDevices = async (userID, accountID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/devices?filter_owner_id=${userID}`,
        "GET",
        null,
        authToken
      );
      //PENDING SANITIZE DATA
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  };

  getUserSoftphones = async (userID, accountID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/devices?filter_owner_id=${userID}&filter_device_type=softphone&filter_enabled=true`,
        "get",
        null,
        authToken
      );

      const userSoftphones = resp.data.map((softphone) => ({
        id: softphone.id,
        name: softphone.name,
        username: softphone.username,
      }));

      return userSoftphones;
    } catch (error) {
      console.log(error);
    }
  };

  getAccountDetails = async (accountID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}`,
        "GET",
        null,
        authToken
      );
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  };

  getAccountUsers = async (accountID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/users`,
        "GET",
        null,
        authToken
      );
      //PENDING SANITIZE DATA
      const directory = resp.data.map((contact) => ({
        id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        name: `${contact.first_name} ${contact.last_name}`,
        ext: contact.presence_id,
        status: "available",
        avatar: "",
      }));
      return directory;
    } catch (error) {
      console.log(error);
    }
  };

  //WORK IN PROGRESS
  getAccountUserByUsername = async (accountID, username, authToken) => {
    try {
      return await this.makeRequest(
        `/accounts/${accountID}/users?filter_email=${username}`,
        "GET",
        null,
        authToken
      );
    } catch (error) {
      console.log(error);
    }
  };

  getDeviceByID = async (accountID, deviceID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/devices/${deviceID}`,
        "GET",
        null,
        authToken
      );
      // console.log(resp.data);
      return {
        id: resp.data.id,
        name: resp.data.name,
        username: resp.data.sip.username,
        password: resp.data.sip.password,
      };
    } catch (error) {
      console.log(error);
    }
  };

  queueCheckIsAgent = async (accountID, userID, authToken) => {
    try {
      const response = await this.makeRequest(
        `/accounts/${accountID}/qubicle_recipients`,
        "GET",
        null,
        authToken
      );
      const agent = response.data.filter((agent) => agent.id === userID);
      // console.log(agent);
      return agent.length === 1 ? true : false;
    } catch (error) {
      return error;
    }
  };

  queueBeginSession = async (accountID, userID, authToken) => {
    try {
      const payload = {
        data: {
          action: "login",
          restore_state: true,
        },
      };

      return await this.makeRequest(
        `/accounts/${accountID}/qubicle_recipients/${userID}`,
        "POST",
        payload,
        authToken
      );
    } catch (error) {
      return error;
    }
  };

  queueExitSession = async (accountID, userID, authToken) => {
    try {
      const payload = {
        data: {
          action: "logout",
        },
      };

      return await this.makeRequest(
        `/accounts/${accountID}/qubicle_recipients/${userID}`,
        "POST",
        payload,
        authToken
      );
    } catch (error) {
      return error;
    }
  };

  queueChangeStatus = async (accountID, userID, status, authToken) => {
    try {
      const payload = {
        data: {
          status: status,
        },
      };
      if (status === "away") payload.data.reason = "";
      return await this.makeRequest(
        `/accounts/${accountID}/qubicle_recipients/${userID}/status`,
        "POST",
        payload,
        authToken
      );
    } catch (error) {
      return error;
    }
  };

  queueCheckSessionStatus = async (accountID, userID, authToken) => {
    try {
      const response = await this.makeRequest(
        `/accounts/${accountID}/qubicle_recipients/${userID}/status`,
        "GET",
        null,
        authToken
      );
      if (response.data === "not_logged_in") {
        return "not_logged_in";
      } else {
        return response.data.availability_state;
      }
      // response.data === "not_logged_in" ? return "not_logged_in" : return response.data.availability_state ;
    } catch (error) {
      return error;
    }
  };

  getUserVoiceMailBox = async (accountID, userID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/vmboxes?filter_owner_id=${userID}`,
        "GET",
        null,
        authToken
      );
      if (resp.data.length === 1) {
        return resp.data[0];
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  getUserVoicemails = async (accountID, voiceMailBoxID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/vmboxes/${voiceMailBoxID}/messages`,
        "GET",
        null,
        authToken
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  };

  getRawVoicemail = async (accountID, voiceMailBoxID, mediaID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/vmboxes/${voiceMailBoxID}/messages/${mediaID}/raw`,
        "GET",
        null,
        authToken,
        "blob"
      );
      return resp;
    } catch (error) {
      return error;
    }
  };

  getVoicemailTranscription = async (
    accountID,
    voiceMailBoxID,
    mediaID,
    authToken
  ) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/vmboxes/${voiceMailBoxID}/messages/${mediaID}`,
        "GET",
        null,
        authToken
      );
      // console.log(resp);
      return resp.data.transcription?.text;
    } catch (error) {
      return error;
    }
  };

  deleteVoicemail = async (accountID, voiceMailBoxID, mediaID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/vmboxes/${voiceMailBoxID}/messages/${mediaID}`,
        "DELETE",
        null,
        authToken
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  };

  getUserMainCallflow = async (accountID, userID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/callflows/?paginate=false&filter_owner_id=${userID}&filter_type=mainUserCallflow`,
        "GET",
        null,
        authToken
      );
      // console.log(resp.data);
      return resp.data;
    } catch (error) {
      return error;
    }
  };

  getAccountExternalNumbers = async (accountID, authToken) => {
    try {
      const resp = await this.makeRequest(
        `/accounts/${accountID}/external_numbers`,
        "GET",
        null,
        authToken
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  };

  setUserCallerID = async (userID, accountID, callerIDNumber, authToken) => {
    try {
      const user = await this.getUserDetails(userID, accountID, authToken);
      // user.do_not_disturb.enabled = DND_status;
      if (callerIDNumber !== "") {
        user.caller_id.external.number = callerIDNumber;
      } else {
        delete user.caller_id.external.number;
      }
      const payload = { data: user };

      const response = await this.makeRequest(
        `/accounts/${accountID}/users/${userID}`,
        "POST",
        payload,
        authToken
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
}

const apiInstance = new KazooAPI();
export { apiInstance as KazooAPI };
