import { createContext, useState } from "react";

export const NumberToDialContext = createContext({
  numberToDial: "",
  setNumberToDial: () => null,
});

export const NumberToDialProvider = ({ children }) => {
  const [numberToDial, setNumberToDial] = useState("");
  const value = { numberToDial, setNumberToDial };
  return (
    <NumberToDialContext.Provider value={value}>
      {children}
    </NumberToDialContext.Provider>
  );
};
