// import { createContext, useState } from "react";

// export const DefaultSpeakerContext = createContext({
//   defaultSpeaker: null,
//   setDefaultSpeaker: () => {},
// });

// export const DefaultSpeakerProvider = ({ children }) => {
//   const [defaultSpeaker, setDefaultSpeaker] = useState(null);
//   const value = { defaultSpeaker, setDefaultSpeaker };

//   return (
//     <DefaultSpeakerContext.Provider value={value}>
//       {children}
//     </DefaultSpeakerContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil"; // Ensure the import path matches your project structure

export const DefaultSpeakerContext = createContext({
  defaultSpeaker: null,
  setDefaultSpeaker: () => {},
});

export const DefaultSpeakerProvider = ({ children }) => {
  // Initialize the defaultSpeaker state with a value from localStorage, or default to null
  const [defaultSpeaker, setDefaultSpeaker] = useState(
    () => loadState("defaultSpeaker") || null
  );

  // Use useEffect to listen for changes to defaultSpeaker and save them to localStorage
  useEffect(() => {
    saveState("defaultSpeaker", defaultSpeaker);
  }, [defaultSpeaker]);

  const value = { defaultSpeaker, setDefaultSpeaker };

  return (
    <DefaultSpeakerContext.Provider value={value}>
      {children}
    </DefaultSpeakerContext.Provider>
  );
};
