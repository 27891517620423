import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal"; // Corrected import
import Box from "@mui/material/Box"; // Import Box for additional layout control if needed
import "./ui-loading.styles.scss";

const UILoading = () => {
  return (
    <Modal
      open={true}
      aria-labelledby="loading-spinner"
      aria-describedby="loading-application-content"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // You might not need this style here if you're using Modal properly
      }}
    >
      {/* You can wrap CircularProgress in a Box if you need to control its styling further */}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            color: "white",
            zIndex: 100,
          }}
        />
      </Box>
    </Modal>
  );
};

export default UILoading;
