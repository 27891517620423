import React, { createContext, useContext, useState } from "react";

export const ConsultCallContext = createContext();

export const useConsultCall = () => {
  return useContext(ConsultCallContext);
};

export const ConsultCallProvider = ({ children }) => {
  const [consultCall, setConsultCall] = useState(null);

  return (
    <ConsultCallContext.Provider value={{ consultCall, setConsultCall }}>
      {children}
    </ConsultCallContext.Provider>
  );
};
