import React, { useContext, useState, useEffect } from "react";
import { userDevicesContext } from "../../contexts/user-devices.context";
import { DefaultDeviceContext } from "../../contexts/default-device.context";
import { UserContext } from "../../contexts/user.context";
import { DefaultMicrophoneContext } from "../../contexts/default-microphone.context";
import { DefaultSpeakerContext } from "../../contexts/default-speaker.context";
import { PhoneStatusContext } from "../../contexts/phone-status.context.jsx";
import { SettingsContext } from "../../contexts/settings.context.jsx";
import UILoading from "../ui-loading/ui-loading.component.jsx";
import { useSIP } from "../../contexts/SIPContext.jsx";
import {
  Drawer,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Slider,
  // TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import { KazooAPI as api } from "../../utils/kazoo.js";
import MediaDeviceHelper from "../../utils/mediaDeviceHelper.js";
import "./settings-panel.styles.scss";

const SettingsPanel = ({ isOpen, toggleDrawer }) => {
  const { userDevices } = useContext(userDevicesContext);
  const { volume, setVolume, keysMuted, setKeysMuted } =
    useContext(SettingsContext);
  // const { keysMuted, setKeysMuted } = useContext(SettingsContext);
  const {
    currentUser,
    // setCurrentUser,
    token,
    setToken,
  } = useContext(UserContext);
  const { initilizeUA, destroyUA, changeMicrophone, changeSpeaker } = useSIP();
  const { isRegistered } = useContext(PhoneStatusContext);
  const { defaultDevice, setDefaultDevice } = useContext(DefaultDeviceContext);
  const { defaultMicrophone, setDefaultMicrophone } = useContext(
    DefaultMicrophoneContext
  );
  const { defaultSpeaker, setDefaultSpeaker } = useContext(
    DefaultSpeakerContext
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(
    !defaultDevice ? "" : defaultDevice.id
  );
  const [selectedMicrophone, setSelectedMicrophone] = useState(
    !defaultMicrophone ? "" : defaultMicrophone.deviceId
  );
  const [selectedSpeaker, setSelectedSpeaker] = useState(
    !defaultSpeaker ? "" : defaultSpeaker.deviceId
  );
  const [selectedKeysMuted, setSelectedKeysMuted] = useState(
    !keysMuted ? false : keysMuted
  );
  // const [volume, setVolume] = useState(50);
  // const [muteDTMF, setMuteDTMF] = useState(false);

  const [availableMediaDevices, setAvailableMediaDevices] = useState({
    microphones: [],
    speakers: [],
    // devices: [], // Assuming devices include user devices for simplicity
  });

  useEffect(() => {
    const fetchMediaDevices = async () => {
      const devices = await MediaDeviceHelper.getMediaDevices();
      setAvailableMediaDevices(devices);
    };
    fetchMediaDevices();
  }, []);

  // useEffect(() => {
  //   const fetchMediaDevices = async () => {
  //     const devices = await MediaDeviceHelper.getMediaDevices();
  //     setAvailableMediaDevices(devices);

  //     // After fetching, check if the currently selected microphone and speaker
  //     // are among the available ones, otherwise reset them to avoid warnings
  //     if (
  //       !devices.microphones.some(
  //         (device) => device.deviceId === selectedMicrophone
  //       )
  //     ) {
  //       setSelectedMicrophone(""); // Or set to a valid default if applicable
  //     }
  //     if (
  //       !devices.speakers.some((device) => device.deviceId === selectedSpeaker)
  //     ) {
  //       setSelectedSpeaker(""); // Or set to a valid default if applicable
  //     }
  //   };
  //   fetchMediaDevices();
  // }, [selectedMicrophone, selectedSpeaker]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "microphone":
        setSelectedMicrophone(value);
        // changeMicrophone(value);
        break;
      case "speaker":
        setSelectedSpeaker(value);
        // changeSpeaker(value);
        break;
      case "device":
        setSelectedDevice(value);
        // Device selection logic goes here
        break;
      default:
        break;
    }
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  const handleMuteDTMFChange = (event) => {
    // setKeysMuted(event.target.checked);
    setSelectedKeysMuted(event.target.checked);
  };

  const handleAccept = async (event) => {
    try {
      setIsLoading(true);
      const microphoneSelected = await availableMediaDevices.microphones.filter(
        (device) => device.deviceId === selectedMicrophone
      )[0];
      if (
        defaultMicrophone === null ||
        !isRegistered ||
        microphoneSelected.deviceId !== defaultMicrophone.deviceId
      ) {
        // console.log(microphoneSelected);
        // console.log(defaultMicrophone);
        await setDefaultMicrophone(microphoneSelected);
        changeMicrophone(microphoneSelected.deviceId);
      }

      const speakerSelected = await availableMediaDevices.speakers.filter(
        (device) => device.deviceId === selectedSpeaker
      )[0];

      if (
        defaultSpeaker === null ||
        !isRegistered ||
        speakerSelected.deviceId !== defaultSpeaker.deviceId
      ) {
        await setDefaultSpeaker(speakerSelected);
        changeSpeaker(speakerSelected.deviceId);
      }

      if (selectedKeysMuted !== keysMuted) {
        console.log("Changing muted value to:", selectedKeysMuted);
        setKeysMuted(selectedKeysMuted);
      }

      if (
        defaultDevice === null ||
        !isRegistered ||
        selectedDevice !== defaultDevice.id
      ) {
        await destroyUA();
        const isTokenValid = await api.validateToken(token);
        // console.log(isTokenValid);

        if (isTokenValid) {
          const selectedDeviceDetails = await api.getDeviceByID(
            currentUser.account.account_id,
            selectedDevice,
            // currentUser.auth_token
            token
          );
          selectedDeviceDetails.realm = currentUser.account.realm;
          selectedDeviceDetails.username =
            selectedDeviceDetails.username.toLowerCase();
          // console.log(selectedDevice);
          await setDefaultDevice(selectedDeviceDetails);
          // console.log(defaultDevice);
          initilizeUA(selectedDeviceDetails);
        } else {
          const newToken = await api.refreshToken(
            currentUser.auth_hash,
            currentUser.account.account_name
          );
          // await setCurrentUser({ ...currentUser, auth_token: newToken });
          setToken(newToken);

          const selectedDeviceDetails = await api.getDeviceByID(
            currentUser.account.account_id,
            selectedDevice,
            // currentUser.auth_token
            newToken
          );
          selectedDeviceDetails.realm = currentUser.account.realm;
          selectedDeviceDetails.username =
            selectedDeviceDetails.username.toLowerCase();
          // console.log(selectedDevice);
          await setDefaultDevice(selectedDeviceDetails);
          // console.log(defaultDevice);
          initilizeUA(selectedDeviceDetails);
        }
      }
      toggleDrawer();
      // console.log("CURRENT USER:", currentUser);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSelectionValid =
    selectedDevice && selectedMicrophone && selectedSpeaker;

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      // onClose={toggleDrawer}
      PaperProps={{
        style: {
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          width: "55%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      {isLoading && <UILoading />}
      <Grid container spacing={2} className="settings-panel">
        {/* Microphone and Speaker Selection */}
        <Grid item xs={4}>
          <FormControl fullWidth margin="dense">
            <InputLabel className="input-label">Microphone</InputLabel>
            <Select
              name="microphone"
              value={
                availableMediaDevices.microphones.some(
                  (device) => device.deviceId === selectedMicrophone
                )
                  ? selectedMicrophone
                  : ""
              }
              onChange={handleChange}
              displayEmpty
            >
              {/* Provide a default or loading state option */}
              {availableMediaDevices.microphones.length === 0 ? (
                <MenuItem value="" disabled>
                  Loading microphones...
                </MenuItem>
              ) : (
                availableMediaDevices.microphones.map((micro) => (
                  <MenuItem value={micro.deviceId} key={micro.deviceId}>
                    {micro.label}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel className="input-label">Speaker</InputLabel>
            <Select
              name="speaker"
              value={
                availableMediaDevices.speakers.some(
                  (device) => device.deviceId === selectedSpeaker
                )
                  ? selectedSpeaker
                  : ""
              }
              onChange={handleChange}
              displayEmpty
            >
              {/* Provide a default or loading state option */}
              {availableMediaDevices.speakers.length === 0 ? (
                <MenuItem value="" disabled>
                  Loading microphones...
                </MenuItem>
              ) : (
                availableMediaDevices.speakers.map((speaker) => (
                  <MenuItem value={speaker.deviceId} key={speaker.deviceId}>
                    {speaker.label}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>

        {/* Device Selection and Volume Control */}
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <FormControl fullWidth margin="dense">
            <InputLabel className="input-label">Device</InputLabel>
            <Select
              name="device"
              value={selectedDevice}
              onChange={handleChange}
            >
              {userDevices.map((device) => (
                <MenuItem value={device.id} key={device.id}>
                  {device.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="space-between" marginTop={2}>
            <Button
              variant="contained"
              disabled={!isSelectionValid}
              style={{ flexGrow: 1, marginRight: 8 }}
              onClick={handleAccept}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              style={{ flexGrow: 1, marginRight: 8 }}
              disabled={!isSelectionValid || !isRegistered}
              onClick={toggleDrawer}
            >
              Cancel
            </Button>
          </Box>
        </Grid>

        {/* DTMF Mute Checkbox and volume slider*/}
        <Grid item xs={4}>
          <Typography gutterBottom>Dialpad volume: {volume}</Typography>
          <Slider
            value={volume}
            onChange={handleVolumeChange}
            aria-labelledby="input-slider"
            sx={{ width: `calc(100% - 10px)` }}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedKeysMuted}
                  onChange={handleMuteDTMFChange}
                />
              }
              label="Mute Dialpad"
            />
          </FormGroup>
        </Grid>
      </Grid>
      {/* <Grid
        container
        style={{ padding: 20, paddingTop: 0, justifyContent: "center" }}
      >
        <Button
          variant="contained"
          disabled={!isSelectionValid}
          style={{ marginRight: 8 }}
          onClick={handleAccept}
        >
          Accept
        </Button>
        <Button
          variant="outlined"
          disabled={!isSelectionValid}
          onClick={toggleDrawer}
        >
          Cancel
        </Button>
      </Grid> */}
    </Drawer>
  );
};

export default SettingsPanel;
