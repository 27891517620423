import { useContext, useState } from "react";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ContactCard from "../contact-card/contact-card.component";
import { DirectoryContext } from "../../contexts/directory.context";
// import { useUIState } from "../../contexts/ui-state.context.jsx";

import "./contacts-panel.styles.scss";

const ContactsPanel = ({ isOpen, onClose, onOpen, sidebarWidth }) => {
  const [searchValue, setSearchValue] = useState("");
  const { directory } = useContext(DirectoryContext);
  // const { isContactsPanelOpen, setIsContactsPanelOpen } = useUIState();

  // const handleClose = () => {
  //   setIsContactsPanelOpen(false);
  // };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredContacts = directory.filter((contact) =>
    contact.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Box className="contacts-container">
      <Box className="search-contacts">
        <TextField
          className="search-text-field"
          fullWidth
          placeholder="Search Contacts"
          value={searchValue}
          onChange={handleSearchChange}
          variant="standard"
        />
      </Box>
      <Box className="main-contacts-container" role="presentation">
        <List>
          {filteredContacts.map((contact, index) => (
            <ContactCard key={contact.id} {...contact} />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ContactsPanel;
