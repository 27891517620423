import { useState, useContext } from "react";
import { Drawer, Box } from "@mui/material";
import CallControl from "../call-control/call-control.component";
import Phone from "../phone/phone.component";
import AgentStatusControl from "../queue-control/queue-control.component";
import CallerIDControl from "../caller-id-control/caller-id-control.component";
import { UserContext } from "../../contexts/user.context";
import { AgentContext } from "../../contexts/agent.context";
import UserProfile from "../avatar/avatar.component";

import "./right-panel.styles.scss";

function RightPanel() {
  const [display, setDisplay] = useState("");
  const { isAgent } = useContext(AgentContext);
  const { currentUser } = useContext(UserContext);

  const handleKeyPress = (key) => {
    setDisplay(display + key);
  };

  return (
    <Drawer variant="permanent" anchor="right" open={true}>
      <Box
        sx={{
          width: 286,
          height: "100%",
          backgroundColor: "charcoal",
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          p: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Phone onKeyPress={handleKeyPress} />
        <CallControl />
      </Box>
      {currentUser?.isAdmin ? (
        <Box>
          <CallerIDControl />
        </Box>
      ) : (
        ""
      )}
      {isAgent ? (
        <Box>
          <AgentStatusControl />
        </Box>
      ) : (
        ""
      )}

      <Box className="user-profile-container">
        <UserProfile />
      </Box>
    </Drawer>
  );
}

export default RightPanel;
