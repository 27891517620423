// import { createContext, useState } from "react";

// export const DefaultDeviceContext = createContext({
//   defaultDevice: null,
//   setDefaultDevice: () => {},
// });

// export const DefaultDeviceProvider = ({ children }) => {
//   const [defaultDevice, setDefaultDevice] = useState(null);
//   const value = { defaultDevice, setDefaultDevice };

//   return (
//     <DefaultDeviceContext.Provider value={value}>
//       {children}
//     </DefaultDeviceContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil"; // Make sure this path is correctly set to where your utilities are

export const DefaultDeviceContext = createContext({
  defaultDevice: null,
  setDefaultDevice: () => {},
});

export const DefaultDeviceProvider = ({ children }) => {
  // Initialize defaultDevice state with value from localStorage, or default to null
  const [defaultDevice, setDefaultDevice] = useState(
    () => loadState("defaultDevice") || null
  );

  // Use useEffect to listen for changes to defaultDevice and save them to localStorage
  useEffect(() => {
    saveState("defaultDevice", defaultDevice);
  }, [defaultDevice]);

  const value = { defaultDevice, setDefaultDevice };

  return (
    <DefaultDeviceContext.Provider value={value}>
      {children}
    </DefaultDeviceContext.Provider>
  );
};
