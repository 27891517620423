// src/components/SearchBar/SearchBar.js

import React from "react";
import {
  TextField,
  Box,
  Toolbar,
  InputAdornment,
  IconButton,
} from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import "./search-bar.styles.scss";

const SearchBar = ({ searchTerm, setSearchTerm, disabled }) => {
  const handleDelete = () => {
    setSearchTerm((prev) => prev.slice(0, -1));
  };
  return (
    <Box position="static" className="search-bar">
      <Toolbar
        sx={{
          backgroundColor: "white",
        }}
      >
        <TextField
          className="search-box"
          variant="standard"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={disabled}
          fullWidth
          InputProps={{
            style: { textAlign: "center", color: "black" },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleDelete}
                  edge="end"
                  disabled={disabled}
                >
                  <BackspaceIcon
                    sx={{
                      transform: "scale(0.8)",
                      color: disabled ? "gray" : "black",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
    </Box>
  );
};

export default SearchBar;
