export const showNotification = (title, options) => {
  if (Notification.permission === "granted") {
    const notification = new Notification(title, options);

    notification.onclick = () => {
      window.focus(); // Brings the browser window/tab showing the web app to the foreground
      //   console.log("Notification clicked");
    };
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification(title, options);

        notification.onclick = () => {
          window.focus(); // Same behavior upon click as above
          //   console.log("Notification clicked");
        };
      }
    });
  }
};
