class MediaDeviceHelper {
  static async getMediaDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const microphones = [];
    const speakers = [];
    const cameras = [];
    devices.forEach((device) => {
      switch (device.kind) {
        case "audioinput":
          microphones.push(device);
          break;
        case "audiooutput":
          speakers.push(device);
          break;
        case "videoinput":
          cameras.push(device);
          break;
        default:
          break;
      }
    });

    return {
      microphones,
      speakers,
      cameras,
    };
  }

  static async getDisplay() {
    let stream;
    try {
      stream = await navigator.mediaDevices.getDisplayMedia();
      // Here we get the track for the screen. This can be used for screen sharing.
      const screenTrack = stream.getVideoTracks()[0];
      return screenTrack;
    } catch (err) {
      console.error("Error getting display:", err);
      throw err;
    } finally {
      // Cleanup the stream if we got one
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    }
  }
}

export default MediaDeviceHelper;

// export default MediaDeviceHelper;

// const mediaHelper = new MediaDeviceHelper();
// export { mediaHelper as MediaDeviceHelper };
