import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const ErrorDialog = ({ open, onClose, errorMessage }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <DialogTitle id="error-dialog-title">{"Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="error-dialog-description">
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
